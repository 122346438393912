import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { HTTP, Uploader } from '../libs/http.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    data: {},
    currentTile: '',
    mapMode: 'overlay',
    tutorialMode: false,
    allMaps: [],
    mapsWithTiles: [],
    center: {},
    tileX: 0,
    tileY: 0,
    zoom: 14,
    user: null,
    myCollections: null,
    myChannels: null,
    collections: null,
    currentCollection: null,
    mapsInCurrentCollection: [],
    nowMoving: false,
    apiTestAnswer: {},
    currentMaps: {
      1: {},
      2: {}
    },
    shareLink: window.location.href,
    collectionToCreateTmp: {},
    lastCreatedCollection: null,
    currentPlace: null,
    tilebarsVisibility: {
      1: false,
      2: false
    },
    initOverlayData: {
      'mapId': 2,
      'id': 'link.mapwarpermap32092'
    },
    initBasemapData: {
      'mapId': 1,
      'id': 'link.mapwarpermap18587'
    },
    cities: {},
    selectedPlace: {
      name: '',
      state: '',
      id: null
    },
    bounds: {},
    modalShown: false,
    currentModalType: null,
    contentBoxShown: false,
    saveStatus: null,
    routerParams: {},
    initMap1: null,
    initMap2: null,
    fitTo: [],
    tooltip: null,
    gcp: null,
    debugInfo: null,
    debugWindow: false
  },
  mutations: {
    setShareLink (state, link) {
      state.shareLink = link
    },
    showDebugWindow (state, options) {
      state.debugWindow = true
      state.debugInfo = options
    },
    hideDebugWindow (state) {
      state.debugWindow = false
      state.debugInfo = null
    },
    showTooltip (state, options) {
      state.tooltip = options
    },
    hideTooltip (state) {
      state.tooltip = null
    },
    setCurrentTile (state, tile) {
      state.currentTile = tile
    },
    clipperX (state, x) {
      if (x) {
        Vue.set(state.routerParams, 'clipperX', x)
      }
    },
    clipperY (state, y) {
      if (y) {
        Vue.set(state.routerParams, 'clipperY', y)
      }
    },
    setPitch (state, pitch) {
      if (typeof pitch !== 'undefined') {
        Vue.set(state.routerParams, 'p', parseFloat(pitch).toFixed(3))
      }
    },
    setBearing (state, bearing) {
      if (typeof bearing !== 'undefined') {
        Vue.set(state.routerParams, 'b', parseFloat(bearing).toFixed(3))
      }
    },
    setInitMap1 (state, map1) {
      Vue.set(state.routerParams, 'map1', map1)

      HTTP.get('https://alpha.mapjunction.com/api/maps/start-info/?id=' + map1).then(response => {
        if (response.data.status === 'ok') {
          if (response.data.data.map.tiles) {
            response.data.data.map.tiles = response.data.data.map.tiles.replace('http://', 'https://')
          }
          state.initMap1 = response.data.data.map
        }
      })
    },
    setInitMap2 (state, map2) {
      Vue.set(state.routerParams, 'map2', map2)

      HTTP.get('https://alpha.mapjunction.com/api/maps/start-info/?id=' + map2).then(response => {
        if (response.data.status === 'ok') {
          if (response.data.data.map.tiles) {
            //  response.data.data.map.tiles = response.data.data.map.tiles.replace('http://', 'https://');
          }
          state.initMap2 = response.data.data.map
        }
      })
    },
    setMoving (state, status) {
      state.nowMoving = status
    },
    setMaps (state, maps) {
      let tmpMapsWithTiles = []
      let idx

      for (idx in maps) {
        let map = maps[idx]

        if (map.tiles.length && map.tiles[0].length) {
          tmpMapsWithTiles.push(map)
        }
      }

      state.allMaps = maps
      state.mapsWithTiles = tmpMapsWithTiles
    },
    showModal (state, data) {
      state.modalShown = true
      state.currentModalType = data.type
    },
    hideModal (state) {
      state.modalShown = false
    },
    toggleContentBox (state, forceStatus) {
      state.contentBoxShown = forceStatus || !state.contentBoxShown
    },
    setMapsByCoords (state, maps) {
      let tmpMapsWithTiles = []
      let idx

      for (idx in maps) {
        let map = maps[idx]
        tmpMapsWithTiles.push(map)
      }

      state.allMaps = maps
      state.mapsWithTiles = tmpMapsWithTiles
    },
    setMapMode (state, mode) {
      Vue.set(state.routerParams, 'mode', mode)
      state.mapMode = mode
    },
    setTutorialMode (state, mode) {
      state.tutorialMode = mode
    },
    setCenter (state, coords) {
      let fixedCount = 7
      let zoom = Math.floor(state.zoom)

      function long2tile (lon, zoom) {
        return (Math.floor((lon + 180) / 360 * Math.pow(2, zoom)))
      }

      function lat2tile (lat, zoom) {
        return (Math.floor((1 - Math.log(Math.tan(lat * Math.PI / 180) + 1 / Math.cos(lat * Math.PI / 180)) / Math.PI) / 2 * Math.pow(2, zoom)))
      }

      state.center = coords
      state.tileX = long2tile(state.center.lng, zoom)
      state.tileY = lat2tile(state.center.lat, zoom)

      if (state.center.lng && state.center.lat) {
        Vue.set(state.routerParams, 'lng', parseFloat(state.center.lng).toFixed(fixedCount))
        Vue.set(state.routerParams, 'lat', parseFloat(state.center.lat).toFixed(fixedCount))
      }
    },
    setZoom (state, zoom) {
      function long2tile (lon, zoom) {
        return (Math.floor((lon + 180) / 360 * Math.pow(2, zoom)))
      }

      function lat2tile (lat, zoom) {
        return (Math.floor((1 - Math.log(Math.tan(lat * Math.PI / 180) + 1 / Math.cos(lat * Math.PI / 180)) / Math.PI) / 2 * Math.pow(2, zoom)))
      }

      if (zoom) {
        zoom = parseFloat(zoom)
        zoom = zoom.toFixed(7)
      }

      state.zoom = zoom
      zoom = Math.floor(zoom)
      state.tileX = long2tile(state.center.lng, zoom)
      state.tileY = lat2tile(state.center.lat, zoom)

      Vue.set(state.routerParams, 'zoom', state.zoom)
      Vue.set(state.routerParams, 'tileX', state.tileX)
      Vue.set(state.routerParams, 'tileY', state.tileY)
    },
    setCurrentMap (state, map) {
      if (map.meta) {
        console.log(map)
        Vue.set(state.routerParams, 'map' + map.mapIdx, map.meta.map_id)
      }

      Vue.set(state.currentMaps, map.mapIdx, map)
    },
    addMapsToCollectionObj (state, map) {
      Vue.set(state.currentCollection.maps, state.currentCollection.maps.length, map)
      Vue.set(state.mapsInCurrentCollection, state.mapsInCurrentCollection.length, map.id)
    },
    updateTilesUrls (state) {
      let idx

      if (!state.tileX || !state.tileY || !state.zoom) {

      }
    },
    setTilebarsVisible (state, options) {
      Vue.set(state.tilebarsVisibility, options.mapId, options.visibility)
    },
    setBounds (state, bounds) {
      Vue.set(state.bounds, 'sw', bounds._sw)
      Vue.set(state.bounds, 'ne', bounds._ne)
    },
    selectedCity (state, cityId) {
      if (state.cities[cityId.id]) {
        let splitedTitle = state.cities[cityId.id].title.split(',')
        let name = splitedTitle.shift()
        let cityState = splitedTitle.join(', ')
        Vue.set(state.selectedPlace, 'name', name)
        Vue.set(state.selectedPlace, 'state', cityState)
        Vue.set(state.selectedPlace, 'id', cityId.id)
      }
    },
    setCities (state, cities) {
      for (let city in cities.places) {
        Vue.set(state.cities, cities.places[city].id, cities.places[city])
      }
    },
    setTestData (state, data) {
      Vue.set(state.apiTestAnswer, data.data.type, data.data)
    },
    setLastUploadedFile (state, data) {
      Vue.set(state.apiTestAnswer, 'lastUploadedFile', data.data)
    },
    setPhotoToCollection (state, data) {
      Vue.set(state.collectionToCreateTmp, 'image', data.data)
    },
    lastCreatedCollection (state, data) {
      state.lastCreatedCollection = data
    },
    setUser (state, data) {
      state.user = data
    },
    deleteUser (state) {
      state.user = null
    },
    cityDetails (state, data) {
      state.currentPlace = data.data.data
    },
    setMyCollections (state, data) {
      state.myCollections = data.data.data.collections
    },
    setMyChannels (state, data) {
      state.myChannels = data.data.data.channels
    },
    setCollections (state, data) {
      state.collections = data.data.data.collections
    },
    setCurrentCollection (state, data) {
      state.currentCollection = data.data.data.collection
    },
    fitTo (state, data) {
      let bounds = data

      if (bounds.northeast && bounds.southwest) {
        state.fitTo.push([bounds.southwest.lng, bounds.southwest.lat])
        state.fitTo.push([bounds.northeast.lng, bounds.northeast.lat])
      }
    },
    setGcp (state, data) {
      state.gcp = data.points
    },
    setGcpEnabled (state, data) {
      Vue.set(state.routerParams, 'gcp', data)
    }
  },
  actions: {
    getMaps ({ commit }) {
      HTTP.get('https://alpha.mapjunction.com/getData.php').then(response => {
        commit('setMaps', response.data)
      })
    },
    getMapsByCoords ({ commit, state }) {
      let bounds = state.bounds
      console.log('get maps by coords', bounds)
      if (bounds.sw && bounds.ne) {
        let bbox = `${bounds.sw.lat},${bounds.sw.lng},${bounds.ne.lat},${bounds.ne.lng}`
        console.log('get maps by coords', bbox)
        HTTP.get('https://alpha.mapjunction.com/api/maps/filter/?bbox=' + bbox).then(response => {
          if (response.data.status === 'ok') {
            commit('setMapsByCoords', response.data.data.maps)
          }
        })
      } else {
        console.log('get maps by coords - nope')
      }
    },
    getCities ({ commit }) {
      HTTP.get('https://alpha.mapjunction.com/api/places/filter/?city=1').then(response => {
        if (response.data.status === 'ok') {
          response.data.type = 'cities'
          commit('setTestData', response)
          commit('setCities', response.data.data)
        }
      })
    },
    createCollection ({ commit }, options) {
      let data = {
        title: options.name,
        is_public: options.is_public
      }

      if (options.background_image) {
        data.background_image = options.background_image
      }

      axios.post('https://alpha.mapjunction.com/api/collections/create/', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if (response.data.status === 'ok') {
          response.data.type = 'newCollection'
          response.data.id = response.data.data.id
          commit('setTestData', response)
          commit('lastCreatedCollection', response)
        }
      })
    },
    createChannel ({ commit }, options) {
      let data = {
        title: options.name,
        is_public: options.is_public
      }

      if (options.background_image) {
        data.background_image = options.background_image
      }

      axios.post('https://alpha.mapjunction.com/api/channels/create/', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if (response.data.status === 'ok') {
          response.data.type = 'newChannel'
          response.data.id = response.data.data.id
          commit('setTestData', response)
        }
      })
    },
    updateCollection ({ commit, state }, options) {
      state.saveStatus = 'saving'
      let saveStatusVisibilityTimeout = 1000

      axios.post('https://alpha.mapjunction.com/api/collections/update/', options, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if (response.data.status === 'ok') {
          response.data.type = 'newCollection'
          response.data.id = options.id
          commit('setTestData', response)

          state.saveStatus = 'saved'

          setTimeout(() => {
            state.saveStatus = null
          }, saveStatusVisibilityTimeout)
        }
      })
    },
    updateChannel ({ commit }, options) {
      let data = {
        id: options.id,
        title: options.name
      }

      axios.post('https://alpha.mapjunction.com/api/channels/update/', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if (response.data.status === 'ok') {
          response.data.type = 'newChannel'
          response.data.id = options.id
          commit('setTestData', response)
        }
      })
    },
    getCollection ({ commit }, data) {
      HTTP.get('https://alpha.mapjunction.com/api/collections/details/?id=' + data.id).then(response => {
        if (response.data.status === 'ok') {
          response.data.type = 'lastCollection'
          commit('setTestData', response)
          commit('setCurrentCollection', response)
        }
      })
    },
    getCollections ({ commit }, data) {
      HTTP.get('https://alpha.mapjunction.com/api/collections/').then(response => {
        if (response.data.status === 'ok') {
          commit('setCollections', response)
        }
      })
    },
    getMyCollections ({ commit }, data) {
      HTTP.get('https://alpha.mapjunction.com/api/collections/my/').then(response => {
        if (response.data.status === 'ok') {
          commit('setMyCollections', response)
        }
      })
    },
    getChannels ({ commit }, data) {
      HTTP.get('https://alpha.mapjunction.com/api/channels/').then(response => {
        if (response.data.status === 'ok') {
          response.data.type = 'channels'
          commit('setTestData', response)
        }
      })
    },
    getMyChannels ({ commit }, data) {
      HTTP.get('https://alpha.mapjunction.com/api/channels/my/').then(response => {
        if (response.data.status === 'ok') {
          commit('setMyChannels', response)
        }
      })
    },
    getPlaceInfo ({ commit }, place) {
      HTTP.get('https://alpha.mapjunction.com/api/places/details/?id=' + place.id).then(response => {
        if (response.data.status === 'ok') {
          response.data.type = 'placeDetails'
          commit('setTestData', response)
          commit('cityDetails', response)
        }
      })
    },
    uploadImage ({ commit }, data) {
      let formData = new FormData()
      formData.append('type', data.type)
      formData.append('file', data.file)
      axios.post('https://alpha.mapjunction.com/api/media/upload/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        commit('setLastUploadedFile', response)
      })
    },
    uploadPhotoToCollection ({ commit }, data) {
      let formData = new FormData()
      formData.append('type', data.type)
      formData.append('file', data.file)
      axios.post('https://alpha.mapjunction.com/api/media/upload/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        commit('setPhotoToCollection', response)
      })
    },
    checkAuth ({ commit }) {
      HTTP.get('https://alpha.mapjunction.com/api/account/me/').then(response => {
        if (response.data.status === 'ok') {
          commit('setUser', response.data.data.user)
        }
      }).catch((e) => {
        commit('deleteUser')
      })
    },
    getInitMapsData ({ commit, state }) {
      commit('setInitMap1', state.initOverlayData.id)
      commit('setInitMap2', state.initBasemapData.id)
    },
    getMapById ({ commit }, mapId) {
      HTTP.get('https://alpha.mapjunction.com/api/maps/start-info/?id=' + mapId).then(response => {
        if (response.data.status === 'ok') {
          if (isNaN(mapId)) {
            let map = response.data.data.map
            commit('clipperX', window.innerWidth - 30)
            commit('setInitMap2', mapId)
            commit('fitTo', map.bbox)
          } else {
            let data = response.data.data.res
            let map1 = data.basemap
            let map2 = data.overlay
            let coords = {
              lng: data.clng,
              lat: data.clat
            }

            commit('setCenter', coords)
            commit('setZoom', data.zoom)
            commit('clipperX', window.innerWidth * data.rclip)
            commit('clipperY', window.innerHeight - window.innerHeight / 2 * data.ropacity)

            HTTP.get('https://alpha.mapjunction.com/api/maps/start-info/?id=' + map1).then(response => {
              if (response.data.status === 'ok') {
                let map = response.data.data.map
                commit('setInitMap1', map1)
              }
            })

            HTTP.get('https://alpha.mapjunction.com/api/maps/start-info/?id=' + map2).then(response => {
              if (response.data.status === 'ok') {
                let map = response.data.data.map
                commit('setInitMap2', map2)
              }
            })
          }
        }
      })
    },
    getGCP ({ commit }, mapId) {
      if (!mapId) {
        commit('setGcp', {
          points: []
        })

        return
      }

      HTTP.get('https://mapwarper.net/api/v1/maps/' + mapId + '/gcps').then(response => {
        if (response.data.data.length) {
          commit('setGcp', {
            points: response.data.data
          })
        }
      })
    }
  }
})
