<template>
    <div class="mj__debug">
        <span class="mj__debug-close" @click="hideDebug()">x</span>
        <div v-if="content.map">
            <p>
            Year: {{ content.map.year ? content.year : '____' }}
            </p>
            <p>
            Name: {{ content.map.title }}
            </p>
            <div>
                etc: <p> {{ content.map }}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'Debug',
  computed: {
    content () {
      return this.$store.state.debugInfo
    }
  },
  methods: {
    hideDebug () {
      this.$store.commit('hideDebugWindow')
    }
  }
}
</script>
<style lang="scss">
    .mj__debug {
        position: fixed;
        z-index: 10013;
        background: rgba(255, 255, 255, 1);
        color: #000;
        right: 10px;
        bottom: 10px;
        padding: 5px 10px;
        border-radius: 3px;
        width: 40vw;
        height: 50vw;
        display: block;
        word-wrap: break-word;
    }
    .mj__debug-close {
        position: absolute;
        right: 0px;
        top: 0px;
        cursor: pointer;
        padding: 10px;
    }
</style>
