<template>
    <div class="mj__inner-content mj__list-grid">
        <router-link :to="'/content/collections/edit/' + item.id" class="mj__list-item" v-for="item in collections" :key="item.id">
            <div class="mj__list-item-bg" v-bind:style="{'background-image': 'url(' + (item.background_image ? item.background_image : '/static/assets/img/empty-city.jpg') + ')'}"></div>
            <div class="mj__list-item-title">
                {{ item.title }}
            </div>
        </router-link>
        <router-link to="/content/collections/create" class="mj__create-sqr">+</router-link>
    </div>
</template>

<script>
export default {
  name: 'MyCollectionsList',
  data () {
    return {
    }
  },
  computed: {
    collections () {
      let collections = this.$store.state.myCollections

      if (collections) {
        collections = collections.reverse()
      }

      return collections
    }
  },
  created () {
    this.$store.dispatch('getMyCollections')
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
    .flex {
        display: flex;
    }
    .mj__content-grid {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 0 0 auto;
    }
    .row {
        margin-bottom: 20px;
    }
</style>
