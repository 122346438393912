<template>
    <div class="mj__inner-content" v-if="currentPlace">
        <router-link :to="'/content/collections/' + cityId" class="mj__content-grid-title">Collections</router-link>
        <template v-if="currentPlace['collections'] && currentPlace['collections'].length">
            <div class="mj__content-grid mj__content-grid--list">
                <router-link :to="'/content/collection/' + collection.id" class="mj__city-item" v-for="(collection, index) in currentPlace['collections']" :key="index">
                    <div class="mj__city-item-bg mj__city-item-bg--empty" v-bind:style="'background-image: url(' + (collection.background_image ? collection.background_image : '/static/assets/img/empty-city.jpg') + ')'"></div>
                    <div class="mj__city-item-title">
                        {{ collection.title }}
                    </div>
                </router-link>
                <router-link to="/content/collections/create" class="mj__create-sqr">+</router-link>
            </div>
        </template>

        <div class="mj__content-grid-title">Channels</div>
        <template v-if="currentPlace['channels'] && currentPlace['channels'].length">
            <div class="mj__content-grid mj__content-grid--list">
                <div class="mj__city-item" v-for="(channel, index) in currentPlace['channels']" :key="index">
                    <div class="mj__city-item-bg mj__city-item-bg--empty" style="background-image: url('/static/assets/img/empty-city.jpg')"></div>
                    <div class="mj__city-item-title">
                        {{ channel.title }}
                    </div>
                </div>
            </div>
        </template>

        <div class="mj__content-grid-title">Articles</div>
        <template v-if="currentPlace['articles'] && currentPlace['articles'].length">
            <div class="mj__content-grid mj__content-grid--list">
                <div class="mj__city-item" v-for="(article, index) in currentPlace['articles']" :key="index">
                    <div class="mj__city-item-bg mj__city-item-bg--empty" style="background-image: url('/static/assets/img/empty-city.jpg')"></div>
                    <div class="mj__city-item-title">
                        {{ article.title }}
                    </div>
                </div>
            </div>
        </template>

        <div class="mj__content-grid-title">Places</div>
        <template v-if="currentPlace['places'] && currentPlace['places'].length">
            <div class="mj__content-grid mj__content-grid--list">
                <div class="mj__city-item" v-for="(place, index) in currentPlace['places']" :key="index">
                    <div class="mj__city-item-bg mj__city-item-bg--empty" style="background-image: url('/static/assets/img/empty-city.jpg')"></div>
                    <div class="mj__city-item-title">
                        {{ place.title }}
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
  name: 'List',
  data () {
    return {
    }
  },
  computed: {
    currentPlace () {
      return this.$store.state.currentPlace
    },
    cityId () {
      return this.$route.params.id
    }
  },
  created () {
    this.$store.dispatch('getPlaceInfo', { id: this.$route.params.id })
  }
}
</script>
