<template>
    <div class="mj__inner-content mj__item"  v-if="currentCollection">
        <div class="mj__header" v-bind:style="'background-image: url(https://alpha.mapjunction.com/images/boston.jpg)'">
            <div class="mj__header-content">
                <Header />
                <div class="mj__collection-head">
                    <div class="mj__collection-image" v-if="currentCollection" v-bind:style="'background-image: url(' + (currentCollection.background_image ? currentCollection.background_image : '/static/assets/img/empty-city.jpg') + ')'">
                    </div>
                    <div class="mj__collection-texts">
                        <div class="mj__collection-title">
                            COLLECTION
                        </div>
                        <div class="mj__collection-name">
                            {{ currentCollection.title }}
                        </div>
                        <div class="mj__collection-subtitle">
                            {{ currentCollection.channel || 'No channel'}}
                        </div>
                        <div class="mj__collection-subtitle">
                            <MapsYears :maps="currentCollection.maps"/>, {{ currentCollection.maps.length }} maps
                        </div>
                    </div>
                </div>
            </div>
            <div class="mj__header-gradient"></div>
        </div>
        <div>
            <v-client-table class="mj__table mj__table--read-only" :columns="columns" :data="currentCollection.maps" :options="options">
                <a slot="flyTo" slot-scope="props" class="">
                    <i class="mj-icon icon-map-placeholder" v-on:dblclick="flyTo(props.row.bbox)"></i>
                </a>

            </v-client-table>
        </div>
    </div>
</template>

<script>
import Header from './Header'
import MapsYears from './MapsYears'
export default {
  name: 'List',
  data () {
    return {
      columns: ['flyTo', 'year', 'title', 'area'],
      options: {
        headings: {
          flyTo: '',
          year: 'Year',
          title: 'Title',
          area: 'Area'
        },
        sortable: [ 'area', 'title', 'year'],
        filterable: ['area', 'title', 'year']
      }
    }
  },
  components: {
    Header,
    MapsYears
  },
  computed: {
    selectedPlace () {
      return this.$store.state.selectedPlace
    },
    user () {
      return this.$store.state.user
    },
    currentCollection () {
      return this.$store.state.currentCollection
    }
  },
  created () {
    this.$store.commit('toggleContentBox', true)
    this.$store.dispatch('getCollection', { id: this.$route.params.id })
  },
  methods: {
    flyTo (bbox) {
      let center = []
      center.push(bbox.northeast.lng + (bbox.northeast.lng - bbox.southwest.lng) / 2)
      center.push(bbox.southwest.lat + (bbox.northeast.lat - bbox.southwest.lat) / 2)

      this.$eventBus.$emit('flyTo', center)
    }
  }
}
</script>
<style lang="scss" scoped>
    .flex {
        display: flex;
    }
    .uploader-test, .mj__content-grid {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 0 0 auto;
    }
    .row {
        margin-bottom: 20px;
    }
</style>
