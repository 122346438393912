<template>
    <div class="mj__modal-add-map">
        <div class="mj__tilebar-search-wrap">
            <span class="geocoder-icon geocoder-icon-search"></span>
            <input type="text" class="mj__tilebar-search" v-model="tilename" placeholder="Enter maptitle"/>
        </div>
        <div class="mj__map-list">
            <div class="mj__map-list-item" v-for="(map, index) in mapsWithTiles" @click="addMap(map)">
                <img :src="map.tiles.replace('{z}', zoom).replace('{y}', tileY).replace('{x}', tileX)" class="mj__map-list-tile-preview" :ref="'img' + index" @error="imageNotFound(index)">
                <div class="mj__map-list-item-title" v-if="map.year">
                    {{ map.year }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'addMapModal',
  data () {
    return {
      tilename: ''
    }
  },
  computed: {
    tileX () {
      return this.$store.state.tileX
    },
    tileY () {
      return this.$store.state.tileY
    },
    zoom () {
      return this.$store.state.zoom
    },
    mapsWithTiles () {
      let maps = this.$store.state.mapsWithTiles
      let filteredMaps = []

      if (this.tilename && this.tilename.length) {
        for (let map in maps) {
          if (maps[map].title.toLowerCase().includes(this.tilename.toLowerCase())) {
            filteredMaps.push(maps[map])
          }
        }

        maps = filteredMaps

        if (filteredMaps.length) {

        }
      }

      return maps
    }
  },
  methods: {
    imageNotFound (index) {
      if (this.$refs['img' + index][0]) {
        this.$refs['img' + index][0].src = '/static/assets/img/empty-map.jpg'
      }
    },
    addMap (map) {
      this.$eventBus.$emit('addMapToCollection', map)
      this.$eventBus.$emit('hideModal')
    }
  }
}
</script>
