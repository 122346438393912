<template>
    <div class="mj__content-half">
        <div class="mj__header" v-bind:style="'background-image: url(https://alpha.mapjunction.com/images/boston.jpg)'">
            <div class="mj__header-content">
                <Header :isprofile="true"/>
                <div class="mj__header-texts" v-if="user">
                    <div class="mj__header-title">
                        {{ user.name }} {{ user.lastname }}
                    </div>
                    <div class="mj__header-subtitle">
                        and there are my:
                    </div>
                </div>
            </div>
            <div class="mj__header-gradient"></div>
        </div>
        <nav class="mj__content-nav">
            <router-link class="mj__content-li" to="/content/mycollections">Collections</router-link>
            <router-link class="mj__content-li" to="/content/myplaces">Places</router-link>
            <router-link class="mj__content-li" to="/content/mychannels">Channels</router-link>
            <router-link class="mj__content-li" to="/content/mystories">Stories</router-link>
            <router-link class="mj__content-li mj__content-li--create" to="/content/story/create">Write a story</router-link>
            <!--<router-link class="mj__content-li" to="/content/test">Api test</router-link>-->
        </nav>
        <router-view class="mj__content-body"></router-view>
    </div>
</template>

<script>
import Header from './Header'
export default {
  name: 'Profile',
  data () {
    return {
    }
  },
  components: {
    Header
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  created () {
    this.$store.commit('toggleContentBox', true)
  }
}
</script>
