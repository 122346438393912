<template>
    <div class="mj__tilebar-wrap" v-bind:class="{'hidden': !barvisibity}" ref="tilebarwrap"
                      v-intro = "introText"
                      v-intro-position="introPosition"
                      v-intro-step="introStep">

        <div class="mj__folder-filter mj__btn-group"  >
            <div class="mj__folder-filter-item mj__btn mj__btn--small" v-bind:class="{active : folder === 'map' }" @click="toggleFilterFolder('map')">
                Map
            </div>
            <div class="mj__folder-filter-item mj__btn mj__btn--small" v-bind:class="{active : folder === 'atlas' }" @click="toggleFilterFolder('atlas')">
                Atlas
            </div>
            <div class="mj__folder-filter-item mj__folder-filter-item--last mj__btn mj__btn--small" v-bind:class="{active : folder === 'aerial' }" @click="toggleFilterFolder('aerial')">
                Aerial
            </div>
        </div>
        <div class="mj__tilebar-search-wrap">
            <input type="text" class="mj__tilebar-search" v-model="tilename" placeholder="Filter maps eg: “harbor”"/>
        </div>
        <div class="mj__tilebar-found-counter">
             <template v-if="mapsLoaded > 0">
                  <template v-if="mapsFound > 0">
                      {{ mapsFound }} maps found
                  </template>
                  <template v-else>
                      maps not found
                  </template>
              </template>
               <template v-else>
                      Loading maps...
                  </template>
        </div>
        <div class="mj__map-list-wrap" >
            <div class="mj__map-list" >
                <div class="mj__map-list-item js-map-list-item" v-for="(map, index) in mapsWithTiles"
                        @click="setTile(map)"
                        @mouseover="mouseOver($event, map)"
                        @mouseout="mouseOut"
                        v-on:dblclick="dblClickHandler(map)"
                         v-bind:key="index">
                   <img :ref="'img' + index" v-if="map.tiles" :src="map.tiles.replace('{z}', parseInt(zoom)).replace('{y}', tileY).replace('{x}', tileX)" class="mj__map-list-tile-preview" @error="imageNotFound(index)" @load="imageFound(index)">
                   <img :ref="'imgDoubleClick' + index" src="/static/assets/img/doubleClick.svg" class="mj__map-list-tile-preview" style="display:none;">
                    <div class="mj__map-list-item-title">
                        {{ map.year ?  map.year :  map.title }}
                    </div>
                </div>
            </div>
        </div>
        <div class="mj__tilebar-current-map" @click="toggleMapSelector(mapId)"   >
            <template v-if="currentMap.title" v-intro="'<b>Choose An Overlay Map</b><br><br>Click the map name to choose a new overlay map.'" v-intro-position="'top'">
                <div class="mj__tilebar-cuurent-map-year" >{{ currentMap.year }}</div>
                <div class="mj__tilebar-cuurent-map-name" v-bind:class="{small : currentMap.title.length > 26}">{{ currentMap.title }}</div>
                <div class="mj__tilebar-cuurent-map-publisher" v-if="currentMap.meta">{{ currentMap.meta.attribute || ''}}</div>
            </template>
            <template v-else>
                <div class="mj__tilebar-cuurent-map-name">Mapbox satellite v9</div>
            </template>
        </div>
    </div>
    </div>
</template>

<script>
export default {
  name: 'Tilebar',
  props: ['mapId'],
  filters: {
    onlyYears (value) {
      if (value && value.length) {
        return value.slice(0, 4)
      }
    }
  },
  data () {
    return {
      tilename: '',
      folder: '',
      mapName: ''
    }
  },
  computed: {
    introText () {
      if (this.mapId == 2) { return '<b>Choose Maps By Date</b><br><br> Scroll down to go back in time.' } else return '<b>Base Map</b><br><br> Compare your overlay with any base map that you choose.'
    },
    introPosition () {
      if (this.mapId == 2) { return 'right' } else return 'left'
    },
    introStep () {
      if (this.mapId == 2) { return '3' } else return '4'
    },
    tileX () {
      return this.$store.state.tileX
    },
    tileY () {
      return this.$store.state.tileY
    },
    zoom () {
      return this.$store.state.zoom
    },
    currentMap () {
      return this.$store.state.currentMaps[this.mapId]
    },
    currentTile () {
      return this.$store.state.currentTile
    },
    mapsFound () {
      return this.mapsWithTiles.length
    },
    mapsLoaded () {
      return this.$store.state.mapsWithTiles.length
    },
    tilebarsVisibility () {
      return this.$store.state.tilebarsVisibility
    },
    mapsWithTiles () {
      if (!this.tilebarsVisibility[1] && !this.tilebarsVisibility[2]) {
        return []
      }

      let maps = this.$store.state.mapsWithTiles
      let filteredMaps = []

      if (this.tilename && this.tilename.length) {
        for (let map in maps) {
          let mapYear = maps[map].year + ''

          if (maps[map].title.toLowerCase().includes(this.tilename.toLowerCase()) || mapYear.includes(this.tilename.toLowerCase())) {
            filteredMaps.push(maps[map])
          }
        }

        maps = filteredMaps
      }

      if (maps.length) {
        if (this.folder) {
          maps = this.filterByFolder(maps)
        }
      }

      return maps
    },
    barvisibity () {
      return this.$store.state.tilebarsVisibility[this.mapId]
    }
  },
  mounted () {
    document.addEventListener('click', this.clickHandler)
  },
  methods: {
    toggleFilterFolder (folder) {
      if (this.folder === folder) {
        this.folder = ''
      } else {
        this.folder = folder
      }
    },
    filterByFolder (maps) {
      let filteredMaps = []
      for (let map in maps) {
        let folder = maps[map].meta.folder.toLowerCase()

        if (this.folder === folder) {
          filteredMaps.push(maps[map])
        }
      }

      return filteredMaps
    },
    setTile (map) {
      console.log(map)

      var attr = map.meta.attribute
      if (attr == null) { attr = 'Source' }

      let prefix = (this.mapId === '2') ? ' Left Map:' : 'Right Map:'

      if (map.meta.alink !== '') { attr = prefix + ' ' + "<a href='" + map.meta.alink + "'  target=\"_blank\">" + attr + '</a>' }
      if (map.meta.alink == null) {
        if (map.meta.attribute == null) { attr = '' } else {
          attr = prefix + ' ' + map.meta.attribute
        }
      }

      let options = {
        mapId: this.mapId,
        tile: map.tiles.replace('http://', 'http://'), // fix after append new api
        attributions: attr
      }

      map.mapIdx = this.mapId
      this.$store.commit('setCurrentMap', map)
      this.$eventBus.$emit('setTile', options)
    },
    hideBars () {
      this.$store.commit('setTilebarsVisible', { mapId: 1, visibility: false })
      this.$store.commit('setTilebarsVisible', { mapId: 2, visibility: false })
    },
    imageNotFound (index) {
      if (this.$refs['img' + index][0]) {
        this.$refs['img' + index][0].style.display = 'none'
        this.$refs['imgDoubleClick' + index][0].style.display = 'block'
      } else {
        this.$refs['imgDoubleClick' + index][0].style.display = 'none'
      }
    },
    imageFound (index) {
      this.$refs['imgDoubleClick' + index][0].style.display = 'none'
      this.$refs['img' + index][0].style.display = 'block'
    },
    toggleMapSelector (mapId) {
      this.$store.dispatch('getMapsByCoords').then(() => {
        this.$store.commit('setTilebarsVisible', { mapId: mapId, visibility: !this.barvisibity })
      })
    },
    clickHandler (event) {
      if (!event.target.closest('.mj__tilebar-wrap')) {
        //   this.hideBars()
      }
    },
    makeCollection () {
      let file = this.$refs.file.files[0]
      this.$store.dispatch('createCollection', file)
    },
    mouseOver (event, map) {
      let el = event.currentTarget
      let offset = el.getBoundingClientRect()
      let showTo = (this.mapId === '2') ? 'right' : 'left'

      this.$store.commit('showTooltip', {
        el: {
          x: offset.left,
          y: offset.top,
          width: el.scrollWidth,
          height: el.scrollHeight
        },
        text: (map.year ? map.year : '') + ' ' + map.title,
        showTo: showTo
      })

      console.log(offset)
    },
    mouseOut (event) {
      this.$store.commit('hideTooltip')
    },
    fitBounds (bbox) {
      this.$eventBus.$emit('fitBounds', bbox)
    },
    showDebugMapInfo (map) {
      this.$store.commit('showDebugWindow', { map })
    },
    dblClickHandler (map) {
      if (this.$route.query.gcp) {
        this.showDebugMapInfo(map)
      } else {
        this.fitBounds(map.bbox)
      }
    }
  }
}
</script>
