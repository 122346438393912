<template>
    <div class="mj__modal-share">
        <h3>Share Mapjunction Location</h3>
        <p>You can copy the URL at any time and send it to other people. They can click the link to return to this
            exact location and settings, including transparency between layers.</p>
        <div class="mj__btn mj__copy-btn" @click="copyToClipboard(shareLink)">
            <template v-if="!copied">
                Copy link
            </template>
            <template v-else>
                Copied
            </template>
        </div>
        <div class="mj__modal-share-icons">
            <a class="mj__soc-btn mj__facebook-ico" target="_blank" :href="'http://www.facebook.com/sharer.php?u=' + soclink"></a>
            <!--<a class="mj__soc-btn mj__gplus-ico" target="_blank" :href="'https://plus.google.com/share?url=' + soclink"></a>-->
            <a class="mj__soc-btn mj__twitter-ico" target="_blank" :href="'https://twitter.com/share?url=' + soclink"></a>
            <a class="mj__soc-btn mj__reddit-ico" target="_blank" :href="'http://reddit.com/submit?url=' + encodeURIComponent(shareLink) + '&title=MapJunction'"></a>
            <a class="mj__soc-btn mj__email-ico" target="_blank" :href="'mailto:?Subject=Shared%20Map%20From%20Mapjunction&Body=' + encodeURIComponent(shareLink) + '&title=MapJunction'"></a>
            <div class="mj__soc-btn mj__code-ico" target="_blank" @click="sharearea = true"></div>
        </div>
        <div v-if="sharearea">
            <textarea autofocus class="mj__soc-textarea"  @focus="$event.target.select()">{{ link }}</textarea>
        </div>
    </div>
</template>

<script>
export default {
  name: 'shareModal',
  data () {
    return {
      location: window.location,
      sharearea: false,
      link: `<iframe src="${this.$store.state.shareLink}" style='border:none; width: 100%; height:800px; padding: 0; margin: 0;'></iframe>`,
      copied: false
    }
  },
  computed: {
    shareLink () {
      return this.$store.state.shareLink
    },
    encodedLink () {
      return encodeURIComponent(this.shareLink)
    },
    soclink () {
      return this.shareLink.replace('#', '%23').split('&').join('%26')
    }
  },
  methods: {
    copyToClipboard (str) {
      const el = document.createElement('textarea')
      el.value = str
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.copied = true
    }
  }
}
</script>
