<template>
    <div>
        <Mapwrap/>
    </div>
</template>

<script>
import Mapwrap from './Map'

export default {
  name: 'Home',
  components: {
    Mapwrap
  },
  computed: {
  },
  methods: {
  }
}
</script>
