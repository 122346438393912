import axios from 'axios'

export const HTTP = axios.create({
  method: 'GET',
  // withCredentials: true,
  baseURL: '',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})

export const Uploader = axios.create({
  method: 'POST',
  withCredentials: true,
  baseURL: '',
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})
