<template>
    <div class="mj__modal" v-bind:class="{'hidden': !modalShown}">
        <div class="mj__modal-overlay" @click="hideModal()">
            <div class="mj__modal-content" @click.stop="()=>{}">
                <div class="mj__modal-close" @click="hideModal()"></div>
                <addMapModal v-if="currentModalType === 'addMap'" />
                <shareModal v-if="currentModalType === 'share'" />
                <gisModal v-if="currentModalType === 'gis'" />
            </div>
        </div>
    </div>
</template>

<script>
import addMapModal from './modals/addMapModal'
import shareModal from './modals/shareModal'
import gisModal from './modals/gisModal'
export default {
  name: 'Modals',
  components: {
    addMapModal,
    shareModal,
    gisModal
  },
  computed: {
    modalShown () {
      return this.$store.state.modalShown
    },
    currentModalType () {
      return this.$store.state.currentModalType
    }
  },
  mounted () {
    window.addEventListener('keyup', (e) => {
      let escKeycode = 27

      if (e.keyCode === escKeycode) {
        this.$store.commit('hideModal')
      }
    })

    this.$eventBus.$on('hideModal', this.hideModal)
  },
  data () {
    return {}
  },
  methods: {
    hideModal () {
      this.$store.commit('hideModal')
    }
  }
}
</script>
