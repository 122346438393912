<template>
    <div class="mj__inner-content mj__content-grid __content-grid--items" v-if="cities">
        <router-link :to="'/content/cities/' + city.id" class="mj__city-item" v-for="(city, index) in cities" :key="index">
            <div class="mj__city-item-bg mj__city-item-bg--empty" style="background-image: url('/static/assets/img/empty-city.jpg')"></div>
            <div class="mj__city-item-title">
                {{ city.title }}
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
  name: 'Cities',
  data () {
    return {
    }
  },
  computed: {
    cities () {
      return this.$store.state.cities
    }
  },
  created () {
    this.$store.dispatch('getCities')
  }
}
</script>
