<template>
    <div class="mj__content-half">
        <div class="mj__header" v-bind:style="'background-image: url(https://alpha.mapjunction.com/images/boston.jpg)'">
            <div class="mj__header-content">
                <Header :isprofile="false"/>
                <div class="mj__header-texts" v-if="selectedPlace">
                    <div class="mj__header-title">
                        {{ selectedPlace.name }}
                    </div>
                    <div class="mj__header-subtitle">
                        {{ selectedPlace.state }} <router-link to="/content/cities/" class="mj__header-change-city mj__empty-button">Other cities</router-link>
                    </div>
                </div>
            </div>
            <div class="mj__header-gradient"></div>
        </div>
        <nav class="mj__content-nav" v-if="selectedPlace">
           <router-link class="mj__content-li" :to="'/content/cities/' + selectedPlace.id">Overview</router-link>
            <router-link class="mj__content-li" :to="'/content/collections/'  + selectedPlace.id">Collections</router-link>
            <router-link class="mj__content-li" :to="'/content/places/'  + selectedPlace.id">Places</router-link>
            <router-link class="mj__content-li" :to="'/content/channels/'  + selectedPlace.id">Channels</router-link>
            <router-link class="mj__content-li" :to="'/content/stories/'  + selectedPlace.id">Stories</router-link>
            <router-link class="mj__content-li mj__content-li--create" to="/content/story/create">Write a story</router-link>
            <router-link class="mj__content-li mj__content-li--create" to="/mjplayer">MJPlayer</router-link>
            <!--<router-link class="mj__content-li" to="/content/test">Api test</router-link>-->
        </nav>
        <router-view class="mj__content-body"></router-view>
    </div>
</template>

<script>
import Header from './Header'
export default {
  name: 'Content',
  data () {
    return {
    }
  },
  components: {
    Header
  },
  computed: {
    cities () {
      return this.$store.state.cities
    },
    selectedPlace () {
      if (this.$route.params.id && this.cities[this.$route.params.id]) {
        let splitedTitle = this.cities[this.$route.params.id].title.split(',')
        let name = splitedTitle.shift()
        let cityState = splitedTitle.join(', ')

        return {
          name,
          state: cityState,
          id: this.$route.params.id
        }
      }
    },
    user () {
      return this.$store.state.user
    }
  },
  created () {
    this.$store.commit('toggleContentBox', true)
  }
}
</script>
