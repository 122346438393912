<template>
    <div class="mj__save-bar" v-bind:class="{shown: status}">
        <span class="mj__save-status mj__save-status--saving" v-if="status === 'saving'"><i class="icon icon-preloader"></i> Saving...</span>
        <span class="mj__save-status mj__save-status--saving" v-if="status === 'saved'"><i class="icon icon-saved"></i> Saved</span>
    </div>
</template>

<script>
export default {
  name: 'Saverstatus',
  props: ['status']
}
</script>
<style>
    .mj__save-bar, .mj__save-status {
        color: #03C651;
        margin-left: 5px;
    }
</style>
