<template>
    <div class="mj__content-half">
        <div class="mj__header mj__header--story">
            <div class="mj__header-content">
                <Header/>
            </div>
            <div class="mj__header-gradient"></div>
        </div>
        <nav class="mj__content-nav" v-if="selectedPlace">
            <router-link class="mj__content-li" :to="'/content/cities/'">Overview</router-link>
            <router-link class="mj__content-li" :to="'/content/collections/' ">Collections</router-link>
            <router-link class="mj__content-li" :to="'/content/places/' ">Places</router-link>
            <router-link class="mj__content-li" :to="'/content/channels/' ">Channels</router-link>
            <router-link class="mj__content-li" :to="'/content/stories/' ">Stories</router-link>
            <!--<router-link class="mj__content-li" to="/content/test">Api test</router-link>-->
        </nav>
        <quill-editor v-model="content"
                      ref="myQuillEditor"
                      :options="editorOption"
                      @blur="onEditorBlur($event)"
                      @focus="onEditorFocus($event)"
                      @ready="onEditorReady($event)">
        </quill-editor>
    </div>
</template>

<script>
import Header from './Header'
export default {
  name: 'Content',
  data () {
    return {
      content: '<h2>I am Exontent/stories/createample</h2>',
      editorOption: {
        // some quill options
      }
    }
  },
  components: {
    Header
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    editor () {
      return this.$refs.myQuillEditor.quill
    }
  },
  created () {
    this.$store.commit('toggleContentBox', true)
  },
  methods: {
    onEditorBlur (quill) {
      console.log('editor blur!', quill)
    },
    onEditorFocus (quill) {
      console.log('editor focus!', quill)
    },
    onEditorReady (quill) {
      console.log('editor ready!', quill)
    },
    onEditorChange ({ quill, html, text }) {
      console.log('editor change!', quill, html, text)
      this.content = html
    }
  }
}
</script>
