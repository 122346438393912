<template>
    <div class="mj__inner-content mj__content-grid">
        <div class="row">
            API TEST
        </div>
        <div class="uploader-test row">
            <div>
                Type: {{ selectedType }} <br>
                <template v-if="apiTestAnswer && apiTestAnswer.lastUploadedFile">
                    UploadedFile: <img style="width: 100px;" :src="apiTestAnswer.lastUploadedFile.data.link" alt="">
                </template>
            </div>
            <div class="flex">
                <button @click="changeType('collection')">Collection</button>
                <button @click="changeType('channel')">channel</button>
                <button @click="changeType('article')">article</button>
            </div>
            <div class="flex">
                <input type="file" ref="uploader">
                <button @click="uploadImage()">Upload</button>
            </div>
        </div>
        <div class="uploader-test row">
            <div>New collection: background-image - last uploaded image</div>
            <div>
                Response: {{ apiTestAnswer.newCollection }}
            </div>
            <div class="flex">
                <input type="text" v-model="articleName">
                <button @click="createCollection()">Create collection</button>
            </div>
        </div>
        <div class="uploader-test row">
            <div>Update collection: background-image - last uploaded image</div>
            <div>
                Response: {{ apiTestAnswer.newCollection }}
            </div>
            <div class="flex">
                <input type="text" v-model="articleNewName">
                <button @click="updateCollection()">Update collection</button>
            </div>
        </div>
        <div class="uploader-test row">
            <div>
                api/collection/detailed/ : {{ apiTestAnswer.lastCollection }}
            </div>
            <div class="flex">
                <button @click="getCollection()">get collection</button>
            </div>
        </div>
        <div class="uploader-test row">
            <div>New channel: background-image - last uploaded image</div>
            <div>
                Response: {{ apiTestAnswer.newChannel }}
            </div>
            <div class="flex">
                <input type="text" v-model="channelName">
                <button @click="createChannel()">Create channel</button>
            </div>
        </div>
        <div class="uploader-test row">
            <div>Update channel: background-image - last uploaded image</div>
            <div>
                Response: {{ apiTestAnswer.newChannel }}
            </div>
            <div class="flex">
                <input type="text" v-model="channelNewName">
                <button @click="updateChannel()">Update channel</button>
            </div>
        </div>
        <div class="uploader-test row">
            <div>
                api/channels/my/ : {{ apiTestAnswer.channels }}
            </div>
            <div class="flex">
                <button @click="getChannels()">get channels</button>
            </div>
        </div>
        <div class="uploader-test row">
            <div>
                api/places/filter/ : {{ apiTestAnswer.cities }}
            </div>
        </div>
        <div class="uploader-test row">
            <div>
                api/places/details/ : {{ apiTestAnswer.placeDetails }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'List',
  data () {
    return {
      selectedType: 'collection',
      articleName: '',
      articleNewName: '',
      channelName: '',
      channelNewName: ''
    }
  },
  computed: {
    apiTestAnswer () {
      return this.$store.state.apiTestAnswer
    }
  },
  created () {
    this.$store.dispatch('getCities')
  },
  methods: {
    changeType (type) {
      this.selectedType = type
    },
    uploadImage () {
      let file = this.$refs.uploader.files[0]
      this.$store.dispatch('uploadImage', { file, type: this.selectedType })
    },
    createCollection () {
      let data = {
        name: this.articleName,
        is_public: 1
      }

      if (this.apiTestAnswer.lastUploadedFile) {
        data.background_image = this.apiTestAnswer.lastUploadedFile.data.id
      }

      this.$store.dispatch('createCollection', data)
    },
    updateCollection () {
      let data = {
        id: this.apiTestAnswer.newCollection.id || 0,
        title: this.articleNewName
      }

      this.$store.dispatch('updateCollection', data)
    },
    getCollection () {
      let data = {
        id: this.apiTestAnswer.newCollection.id
      }

      this.$store.dispatch('getCollection', data)
    },
    createChannel () {
      let data = {
        name: this.channelName,
        is_public: 1
      }

      if (this.apiTestAnswer.lastUploadedFile) {
        data.background_image = this.apiTestAnswer.lastUploadedFile.data.id
      }

      this.$store.dispatch('createChannel', data)
    },
    updateChannel () {
      let data = {
        id: this.apiTestAnswer.newChannel.id,
        name: this.channelNewName
      }

      this.$store.dispatch('updateChannel', data)
    },
    getChannels () {
      this.$store.dispatch('getChannels')
    }

  }
}
</script>
<style lang="scss" scoped>
    .flex {
        display: flex;
    }
    .uploader-test, .mj__content-grid {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 0 0 auto;
    }
    .row {
        margin-bottom: 20px;
    }
</style>
