<template>
    <div class="mj__flex mj__app-wrap" v-bind:style="'height:' + innerHeight + 'px'"  v-intro="'<b>Welcome to the New Mapjunction!</b><br><br>Take a few seconds and learn more about Mapjunction. <br><br> You can also go back to <a href=\'https://legacy.mapjunction.com/\'>legacy.mapjunction.com</a> if you prefer.'">
        <div class="mj__map-wrap flex-col" v-bind:class="{half: contentBoxShown}" >
            <nav class="mj__nav" v-bind:class="{moving: nowMoving }" v-bind:style='{ "z-index": computedZindex }'> <!--  -->

                <span class="mj__btn" v-bind:class="{ active: mode == 'overlay'}" @click="setMapMode('overlay')">
                  <i class="mj-icon icon-overlay"></i> <span class="mj__nav-text">Overlay</span></span>
                <span v-intro="'Try each of these views for comparing maps. Hold down Control and you can even tilt any map. (Desktop only)'"
                v-intro-highlight-class="'blue-bg'" style="z-index: 10000"
                class="mj__btn" v-bind:class="{active: mode == 'horizontal'}" @click="setMapMode('horizontal')">
                <i class="mj-icon icon-sbs-h"></i>  <span class="mj__nav-text">Horizontal</span>
                </span>
                <span class="mj__btn last" v-bind:class="{ active: mode == 'vertical'}" @click="setMapMode('vertical')">
                  <i class ="mj-icon icon-sbs-v"></i> <span class="mj__nav-text">Vertical</span>
                  </span>

                <div v-intro="'<b>About Mapjunction</b><br><br>Check out this page for tutorials and background on Mapjunction. '" class="mj__btn mj__btn--about " @click="showWebsite()">About Mapjunction</div>
                <div v-intro="'<b>Contact us</b><br><br>We\'d love to hear from you. We are happy to add new maps. Just tell us which ones! '" class="mj__btn mj__btn--contact " @click="showContactUs()">Contact Us</div>

                <div class="mj__btn--share" @click="showShareModal()" v-intro="'<b>Share!</b><br><br> Find something interesting? Share it!'" v-intro-step="6"><i class="mj__share-btn"></i></div>
                <div class="mj__btn--gis" @click="showGisModal()" v-if="checkGis()">GIS</div>
                <div class="mj__btn--share mj__btn--3d" v-intro="'<b> 3D View</b> <br><br> If you zoom in far enough, click to see a 3D view. You can then choose Street View.'"  v-intro-step="5" @click="show3d()">3D</div>
            </nav>
           <!-- <div class="mj__btn mj__btn--content-box" @click="toggleContentBox()">CB</div>-->
            <div class="mj__content">
                <Mapwrap/>
            </div>
        </div>
        <div class="mj__content-box flex-col" v-bind:class="{half: contentBoxShown}" >
            <router-view></router-view>
        </div>
        <Modals/>
        <Debug v-if="debugWindow"/>
        <Tooltip />
        <div  v-show="iframeshown" @click="iframeshown = false" class="mj__3d-bg">

            <div class="mj__3d-bg-helper">

                <span class="mj__btn" v-show="show3dmode" @click.stop="show3d(true)">

                <span class="mj__nav-text">Switch to Street View</span></span>

                <span class="mj__btn" v-show="showSvmode" @click.stop="show3d()">

                <span class="mj__nav-text">Switch to Google Aerial</span></span>

                <div class="mj__btn--close-3d" @click="iframeshown = false">x</div>

            </div>
            <span style="color:white; display: block; position:absolute; top:60px; right:10px;">Zoom in to view 3D buildings if available</span>

        </div>
        <div  v-show="aboutshown" class="mj__3d-bg"  style="overflow-y: scroll;" >

          <b-container class="bv-example-row" style="background-color:white; ">

             <b-row >
                <b-col sm="1" offset = "11" style="margin-top:10px"> <b-button @click="aboutshown = false">Close</b-button></b-col>
             </b-row>
              <b-row>

               <b-col sm="8" offset = "2">
<hr />
<h2 style="text-align: center;"><span style="color: #808080;">Mapjunction Background and Tutorials</span></h2>
<p style="text-align: center;"><span style="color: #808080;">by Bill Warner - November 18, 2019</span></p>
<hr />
<p style="text-align: left;">&nbsp;</p>
<h2><span style="color: #808080;">Tutorial</span></h2>
<p><iframe src="//player.vimeo.com/video/374276007?title=0&amp;amp;byline=0" width="650
  " height="400" allowfullscreen="allowfullscreen"></iframe></p>
<p><span style="color: #808080;">This tutorial gives you an overview of all the new features in the context of exploring Boston history.</span></p>
<h2 style="text-align: left;"><span style="color: #808080;">Background</span></h2>
<p><span style="color: #808080;">Mapjunction was born way back in 1999 when I got involved with the City of Boston to make a video about the Boston as the new millenium approached. You can watch it <strong><a style="color: #808080;" href="https://www.youtube.com/watch?v=3OIyfmsWOmQ" target="_blank" rel="noopener">here</a></strong>.</span></p>
<p><span style="color: #808080;">I got ever more fascinated about the history of Boston, and I heard that 2/3 of Boston was filled land. But where was the boundary? I had always wanted a way to overlay historic maps with current ones. I set out to build an online system that could allow us to aligin historic maps and display them on any browser.&nbsp;</span></p>
<p><span style="color: #808080;">To build the system, I turned to Greg Cockroft, an engineer I had worked with at the very beginning of <strong><a style="color: #808080;" href="http://www.avid.com" target="_blank" rel="noopener">Avid Technology</a></strong>, a company I started to make non-linear editing systems. Greg designed an amazing online sysem using Java applets. It delivered a tiled, high performance interactive mapping system five years before Google Maps hit the scene.</span></p>
<p><span style="color: #808080;">We even built tools to process an entire Bromley Atlas and make it into a single, gigantic image that you could explore with ease.</span></p>
<p><span style="color: #808080;">The City of Boston's planning agency was called the Boston Redevelopment Authority back in 2000. Martin Von Wyss was running the GIS office, and he approached us about making Mapjunction work in special ways for the City. We did it, and a special version of Mapjunction focused on Boston was born and was named <strong><a style="color: #808080;" href="http://www.bostonplans.org/3d-data-maps/historical-maps/the-boston-atlas" target="_blank" rel="noopener">The Boston Atlas</a></strong>. Now, 19 years later, the Boston Atlas lives on as part of Boston's website.</span></p>
<p><span style="color: #808080;">Mapjunction has undergone many changes over the years, but the basic idea is always the same: Give people a fast, fun, easy way to compare past, present and future maps.</span></p>
<h2><span style="color: #808080;">Videos Made With Mapjunction</span></h2>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><iframe src="//www.youtube.com/embed/xwGW67tKxPI" width="560" height="314" allowfullscreen="allowfullscreen"></iframe></p>
<p><span style="color: #808080;">There is a huge project underway to move the Mass Pike and replace the elevated section of the highway. This will create a whole new neighborhood in Boston. This animation will show you all the pieces of the new project, using maps from Mapjunction.</span></p>
<p><br /><span style="color: #808080;"><iframe src="//www.youtube.com/embed/30e3s4F4b-k" width="560" height="314" allowfullscreen="allowfullscreen"></iframe></span></p>
<p><span style="color: #808080;">MIT moves to Boston in 1919. This video gives you the backstory and the maps. The entire campus was paid for by one man who wished to remain anonymous. Now we know his name. It's quite a story.</span></p>
<p>&nbsp;</p>
<p><span style="color: #808080;"><iframe src="//www.youtube.com/embed/gx5pgWm8KFI" width="560" height="314" allowfullscreen="allowfullscreen"></iframe></span></p>
<p><span style="color: #808080;">For a long time, Boston had two major league teams, the Redsox in the American League and the Boston Braves in the National League. But where did the Braves play? Find out here.</span></p>

</b-col>
              </b-row>
            </b-container>

        </div>

        <iframe name="iframea"  v-show="iframeshown" id="myiframe" width="100%"></iframe>
        {{ routerParams }}

    </div>

</template>

<script>
import Mapwrap from './components/Map'
import Sidebyside from './components/Sidebyside'
import Modals from './components/Modals'
import Tooltip from './components/Tooltip'
import Debug from './components/Debug'
import introJs from 'intro.js'

export default {
  name: 'App',
  components: {
    Mapwrap,
    Sidebyside,
    Modals,
    Tooltip,
    Debug

  },
  data () {
    return {
      appData: {},
      innerHeight: window.innerHeight,
      routerQuery: null,
      iframeshown: false,
      aboutshown: false,
      show3dmode: true,
      showSvmode: false,
      zIndex: '9999999  !important'
    }
  },
  watch: {
    '$route.params.startMapId' (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {

      }
    },
    routerQuery: {
      handler (val) {
        let lat = val.lat
        let lng = val.lng
        let clipperX = val.clipperX
        let clipperY = val.clipperY
        let map1 = val.map1 || ''
        let map2 = val.map2 || ''
        let zoom = val.zoom
        let mode = val.mode || 'overlay'
        let b = val.b || 0
        let p = val.p || 0
        let gcp = val.gcp

        clearTimeout(this.updateTimeout)

        this.updateTimeout = setTimeout(() => {
          this.$router.replace({
            query: {
              lat,
              lng,
              clipperX,
              clipperY,
              map1,
              map2,
              zoom,
              mode,
              b,
              p,
              gcp
            }
          })
        }, 300)
      },
      deep: true
    }
  },
  computed: {
    computedZindex: function () {
      return this.zIndex
    },
    debugWindow () {
      return this.$store.state.debugWindow
    },
    center () {
      return this.$store.state.center
    },
    zoom () {
      return this.$store.state.zoom
    },
    routerParams () {
      this.routerQuery = this.$store.state.routerParams
    },
    contentBoxShown () {
      return this.$store.state.contentBoxShown
    },
    mode () {
      return this.$store.state.mapMode
    },
    nowMoving () {
      return this.$store.state.nowMoving
    },
    overlayMapId () {
      return this.$store.state.initOverlayData.id
    },
    baseMapId () {
      return this.$store.state.initBasemapData.id
    }
  },
  created () {
    this.fetchData()
    this.$store.dispatch('checkAuth')
    this.$store.dispatch('getCities')

    if (this.$route.query.gcp) {
      this.$store.dispatch('getGCP')
    }
  },
  mounted () {
    this.initAppParams()

    // const bodyScrollLock = require('body-scroll-lock')
    // const disableBodyScroll = bodyScrollLock.disableBodyScroll
    const introJS = require('intro.js')

    if (document.documentElement.clientWidth > 900) {
      introJS.introJs().setOption('scrollToElement', false).setOption('disableInteraction', 'true').setOption('showStepNumbers', 'false').onexit(function () {
        // alert('something is not working here')
      //  this.setTutorialMode(false)
      }).start()
    } else {
      this.setZIndex()
    }
    // this.setTutorialMode(true)
    window.addEventListener('resize', () => {
      clearTimeout(this.resizeTimeout)

      this.resizeTimeout = setTimeout(() => {
        this.innerHeight = window.innerHeight
      }, 200)
    })

    this.$nextTick(() => {
      if (this.$route.params && this.$route.params.startMapId) {
        this.$store.dispatch('getMapById', this.$route.params.startMapId)
      }
    })
  },
  methods: {

    setZIndex: function (event) {
      this.zIndex = 10001
    },

    hideTilebar: function (event) {
      this.$store.commit('setTilebarsVisible', { mapId: 1, visibility: false })
      this.$store.commit('setTilebarsVisible', { mapId: 2, visibility: false })
    },

    initAppParams () {
      let urlParams = this.$route.query

      this.$store.commit('setZoom', urlParams.zoom)
      this.$store.commit('clipperX', urlParams.clipperX)
      this.$store.commit('clipperY', urlParams.clipperY)
      this.$store.commit('setPitch', urlParams.p)
      this.$store.commit('setBearing', urlParams.b)
      this.$store.commit('setMapMode', urlParams.mode)
      this.$store.commit('setGcpEnabled', urlParams.gcp)

      let coords = {
        lat: urlParams.lat,
        lng: urlParams.lng
      }

      this.$store.commit('setCenter', coords)

      if (!urlParams.map1) {
        urlParams.map1 = this.overlayMapId
      }

      this.$store.commit('setInitMap1', urlParams.map1)

      if (!urlParams.map2) {
        urlParams.map2 = this.baseMapId
      }

      this.$store.commit('setInitMap2', urlParams.map2)
    },
    fetchData () {
      this.$store.dispatch('getMapsByCoords').then(() => {
        this.$store.commit('setTilebarsVisible', { mapId: 2, visibility: true })
      })
    },
    toggleContentBox () {
      this.$store.commit('toggleContentBox')
      this.$router.push('/content/cities/5b0add339cb449bf27d7199b')
    },
    setMapMode (mode) {
      this.$store.commit('setMapMode', mode)
    },
    setTutorialMode (mode) {
      this.$store.commit('setTutorialMode', mode)
    },
    show3d (street) {
      let c = this.center
      let url = 'https://legacy.mapjunction.com/mj/start/gm/' + c.lat + '/' + c.lng + '/' + (parseFloat(this.zoom) + 1)

      if (street) {
        this.show3dmode = false
        this.showSvmode = true
        url = 'https://legacy.mapjunction.com/mj/start/sv/' + c.lat + '/' + c.lng + '/' + (parseFloat(this.zoom) + 1)
      } else {
        this.show3dmode = true
        this.showSvmode = false
      }

      this.iframeshown = true
      window.open(url, 'iframea')
    },

    showContactUs () {
      var body = '%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%0AThanks%20for%20sending%20feedback%20about%20Mapjunction%21%0A%0ALet%20us%20know%20if%20you%E2%80%99d%20like%20new%20maps%20added%E2%80%A6.we%20can%20do%20that%21%0A%0ABill%20Warner%0AFounder%20of%20Mapjunction%0A%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%2B%0AStart%20your%20message%20below%3A%0A%0A%0A%0A%0A%0A%0A' + '%0A%0AURL%20link%20from%20the%20current%20state%3A%0A' + window.location.href
      body = body.replace(new RegExp('&', 'g'), '%26')
      window.open('mailto:bill@warnerresearch.com?subject=alpha.mapjunction.com%20feedback&body=' + body)
    },

    showWebsite () {
      this.aboutshown = true
      // var win = window.open('https://vimeo.com/203743538', '_blank')
      // win.focus()
    },

    showShareModal () {
      this.$store.commit('setShareLink', window.location.href)
      this.$store.commit('showModal', { type: 'share' })
    },

    checkGis () {
      var flag1 = false
      var flag2 = false
      var flag10 = false
      var flag20 = false
      if (this.$store.state.currentMaps[2].meta != null) {
        flag1 = this.$store.state.currentMaps[2].meta.map_id.includes('link.mapwarpermap')
        flag10 = this.$store.state.currentMaps[2].meta.map_id.includes('mosaic.mapwarperlayer')
      }
      if (this.$store.state.currentMaps[1].meta != null) {
        flag2 = this.$store.state.currentMaps[1].meta.map_id.includes('link.mapwarpermap')
        flag20 = this.$store.state.currentMaps[1].meta.map_id.includes('mosaic.mapwarperlayer')
      }
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return false
      }
      return flag1 || flag2 || flag10 || flag20
    },

    showGisModal () {
      this.$store.commit('showModal', { type: 'gis' })
    }

  }
}
</script>
<style>
    .mj__app-wrap {
        /*min-height: 100vh;*/
    }
</style>
