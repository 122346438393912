import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import 'normalize.css'
// Tell Vue to use the plugin
// import './../target/css/main.css';
// import VueQuillEditor from 'vue-quill-editor'
import Tooltip from 'vue-directive-tooltip'
import VueIntro from 'vue-introjs'
import BootstrapVue from 'bootstrap-vue'

import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css'

import { ServerTable, ClientTable, Event } from 'vue-tables-2'
// require styles
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
import 'intro.js/introjs.css'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(VueIntro)
Vue.use(BootstrapVue)
Vue.use(Tooltip)
// Vue.use(VueQuillEditor /* { default global options } */)

Vue.prototype.$eventBus = new Vue()
Vue.use(ClientTable)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
  template: '<App/>',
  components: { App }
}).$mount('#mapjunction')
