<template>
    <div class="mj__tooltip" v-bind:style="position" v-bind:class="[{shown: tooltip}, showTo]" ref="tooltip">
        {{ content }}
    </div>
</template>
<script>
export default {
  name: 'Tooltip',
  computed: {
    tooltip () {
      return this.$store.state.tooltip
    },
    content () {
      if (this.tooltip) {
        return this.tooltip.text
      }
    },
    showTo () {
      if (this.tooltip) {
        return this.tooltip.showTo
      }
    },
    position () {
      let x = 0
      let y = 0
      let showOffset = 10

      if (this.tooltip) {
        if (this.tooltip.showTo === 'left') {
          x = window.innerWidth - parseInt(this.tooltip.el.x) + showOffset
          y = this.tooltip.el.y + this.tooltip.el.height / 2 - this.$refs.tooltip.scrollHeight / 2

          return `top: ${y}px; right: ${x}px`
        }

        if (this.tooltip.showTo === 'right') {
          x = parseInt(this.tooltip.el.x) + parseInt(this.tooltip.el.width) + showOffset
          y = this.tooltip.el.y + this.tooltip.el.height / 2 - this.$refs.tooltip.scrollHeight / 2

          return `top: ${y}px; left: ${x}px`
        }
      }
    }
  }
}
</script>

<style lang="scss">
    .mj__tooltip {
        position: fixed;
        z-index: 10003;
        background: rgba(#000, 0.8);
        color: #fff;
        padding: 5px 10px;
        border-radius: 3px;
        transform: scale(0);
        transform-origin: left;
        transition: transform 0.195s ease-in-out 0.2s;

        &.left {
            transform-origin: right !important;
            &:after {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 2.5px 0 2.5px 3px;
                border-color: transparent transparent transparent rgba(#000, 0.8);
                position: absolute;
                right: -3px;
                top: 50%;
                margin-top: -2.5px;
            }
        }

        &.right {
            transform-origin: left !important;
            &:after {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 2.5px 3px 2.5px 0;
                border-color: transparent rgba(#000, 0.8) transparent transparent;
                position: absolute;
                left: -3px;
                top: 50%;
                margin-top: -2.5px;
            }
        }

        &.shown {
            transform: scale(1);
        }
    }
</style>
