<template>
    <div class="mj__inner-content mj__list-grid">
        <router-link :to="'/content/' + (entity == 'collections' ?  'collection' : entity) + '/' + item.id" class="mj__list-item" v-for="item in entityList" :key="item.id">
            <div class="mj__list-item-bg" v-bind:style="{'background-image': 'url(' + (item.background_image ? item.background_image : '/static/assets/img/empty-city.jpg') + ')'}"></div>
            <div class="mj__list-item-title">
                {{ item.title }}
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
  name: 'PlaceEntityList',
  data () {
    return {
    }
  },
  props: ['entity'],
  computed: {
    entityList () {
      if (this.$store.state.currentPlace) {
        return this.$store.state.currentPlace[this.entity]
      }
    }
  },
  created () {
    this.$store.dispatch('getPlaceInfo', { id: this.$route.params.id })
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
    .flex {
        display: flex;
    }
    .mj__content-grid {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 0 0 auto;
    }
    .row {
        margin-bottom: 20px;
    }
</style>
