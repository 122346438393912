<template>
    <div class="mj__modal-gis">
        <h3>Information and GIS Links</h3>
        <p>Most of the maps on Mapjunction have been aligned using <a href="www.mapwarper.net">www.mapwarper.net</a>.</br>
We also try to upload lots of information about the original source of each map.</br>
This page will summarize all of the information we have, and will present many useful download links.</p>

      <b-container class="bv-example-row">
  <b-row>
    <b-col>
        
     
           <b>Left Map</b>: {{mapname2}}<br> 
    Uploaded by Mapjunction. Depicts:  {{mapyear2}} <br><br>
     <p v-if="mwid2!=0"> 
    Preview: <a  :href="'https://mapwarper.net/maps/' + mwid2 + '#Preview_Rectified_Map_tab'" target="_blank">https://mapwarper.net/maps/{{mwid2}}#Preview_Rectified_Map_tab</a> <br>
    Rectify: <a  :href="'https://mapwarper.net/maps/' + mwid2 + '#Rectify_tab'" target="_blank">https://mapwarper.net/maps/{{mwid2}}#Rectify_tab</a> <br>
    Crop:  <a :href="'https://mapwarper.net/maps/' + mwid2 + '#Crop_tab'" target="_blank">https://mapwarper.net/maps/{{mwid2}}#Crop_tab</a><br>
    Export Page: <a :href="'https://mapwarper.net/maps/' + mwid2 + '#Export_tab'" target="_blank">https://mapwarper.net/maps/{{mwid2}}#Export_tab </a><br><br>
<b>Images</b><br>
	• GeoTiff: Download rectified GeoTiff <a :href="'https://mapwarper.net/maps/' + mwid2 + '/export.tif'" target="_blank">https://mapwarper.net/maps/{{mwid2}}/export.tif</a><br>
	• PNG: Download rectified PNG (and associated .aux.xml file .aux.xml) <a :href="'https://mapwarper.net/maps/' + mwid2 + '/export.png'" target="_blank">https://mapwarper.net/maps/{{mwid2}}/export.png</a><br>
<b>Map Services</b><br>
	• KML: Download KML file (for use in Google Earth, etc) <a :href="'https://mapwarper.net/maps/' + mwid2 + '.kml'" target="_blank">https://mapwarper.net/maps/{{mwid2}}.kml</a><br>
	• WMS: WMS Capabilities URL (for JOSM OpenStreetMap Editor ) <a :href="'https://mapwarper.net/maps/wms/' + mwid2 + '?request=GetCapabilities&service=WMS&version=1.1.1'" target="_blank">https://mapwarper.net/maps/wms/{{mwid2}}?request=GetCapabilities&service=WMS&version=1.1.1</a><br>
	• Tiles (Google/OSM scheme): <a :href="'https://mapwarper.net/maps/tile/' + mwid2 + '/0/0/0.png'" target="_blank">https://mapwarper.net/maps/tile/{{mwid2}}/{z}/{x}/{y}.png</a><br>
<b>Ground Control Points</b><br>
	• CSV: Download control points as CSV  <a :href="'https://mapwarper.net/maps/' + mwid2 + '/gcps.csv'" target="_blank">https://mapwarper.net/maps/{{mwid2}}/gcps.csv</a><br>
    </p>
    <p v-else-if="mwid2mosaic!=0"> 
    Preview: <a  :href="'https://mapwarper.net/layers/' + mwid2mosaic + '#Show_tab'" target="_blank">https://mapwarper.net/layers/{{mwid2mosaic}}#Show_tab</a> <br>
            <b>Map Services</b><br>
	• KML: Download KML file (for use in Google Earth, etc) <a :href="'https://mapwarper.net/layers/' + mwid2mosaic + '.kml'" target="_blank">https://mapwarper.net/layers/{{mwid2mosaic}}.kml</a><br>
	• WMS: WMS Capabilities URL (for JOSM OpenStreetMap Editor ) <a :href="'https://mapwarper.net/mosaics/wms/' + mwid2mosaic + '?request=GetCapabilities&service=WMS&version=1.1.1'" target="_blank">https://mapwarper.net/mosaics/wms/{{mwid2mosaic}}?request=GetCapabilities&service=WMS&version=1.1.1</a><br>
	• Tiles (Google/OSM scheme): <a :href="'https://mapwarper.net/mosaics/tile/' + mwid2mosaic + '/0/0/0.png'" target="_blank">https://mapwarper.net/mosaics/tile/{{mwid2mosaic}}/{z}/{x}/{y}.png</a><br>

    </p>
    <p v-else>This map doesn't have <i>mapwarper.net</i> links. </p>
    </b-col>

    <b-col>
      <b>Right Map</b>: {{mapname1}}<br> 
    Uploaded by Mapjunction. Depicts:  {{mapyear1}} <br><br>
     <p v-if="mwid1!=0">  
    Preview: <a  :href="'https://mapwarper.net/maps/' + mwid1 + '#Preview_Rectified_Map_tab'" target="_blank">https://mapwarper.net/maps/{{mwid1}}#Preview_Rectified_Map_tab</a> <br>
    Rectify: <a  :href="'https://mapwarper.net/maps/' + mwid1 + '#Rectify_tab'" target="_blank">https://mapwarper.net/maps/{{mwid1}}#Rectify_tab</a> <br>
    Crop:  <a :href="'https://mapwarper.net/maps/' + mwid1 + '#Crop_tab'" target="_blank">https://mapwarper.net/maps/{{mwid1}}#Crop_tab</a><br>
    Export Page: <a :href="'https://mapwarper.net/maps/' + mwid1 + '#Export_tab'" target="_blank">https://mapwarper.net/maps/{{mwid1}}#Export_tab </a><br><br>
<b>Images</b><br>
	• GeoTiff: Download rectified GeoTiff <a :href="'https://mapwarper.net/maps/' + mwid1 + '/export.tif'" target="_blank">https://mapwarper.net/maps/{{mwid1}}/export.tif</a><br>
	• PNG: Download rectified PNG (and associated .aux.xml file .aux.xml) <a :href="'https://mapwarper.net/maps/' + mwid1 + '/export.png'" target="_blank">https://mapwarper.net/maps/{{mwid1}}/export.png</a><br>
<b>Map Services</b><br>
	• KML: Download KML file (for use in Google Earth, etc) <a :href="'https://mapwarper.net/maps/' + mwid1 + '.kml'" target="_blank">https://mapwarper.net/maps/{{mwid1}}.kml</a><br>
	• WMS: WMS Capabilities URL (for JOSM OpenStreetMap Editor ) <a :href="'https://mapwarper.net/maps/wms/' + mwid1 + '?request=GetCapabilities&service=WMS&version=1.1.1'" target="_blank">https://mapwarper.net/maps/wms/{{mwid1}}?request=GetCapabilities&service=WMS&version=1.1.1</a><br>
	• Tiles (Google/OSM scheme): <a :href="'https://mapwarper.net/maps/tile/' + mwid1 + '/0/0/0.png'" target="_blank">https://mapwarper.net/maps/tile/{{mwid1}}/{z}/{x}/{y}.png</a><br>
<b>Ground Control Points</b><br>
	• CSV: Download control points as CSV  <a :href="'https://mapwarper.net/maps/' + mwid1 + '/gcps.csv'" target="_blank">https://mapwarper.net/maps/{{mwid1}}/gcps.csv</a><br>
    </p>
    <p v-else-if="mwid1mosaic!=0"> 
    Preview: <a  :href="'https://mapwarper.net/layers/' + mwid1mosaic + '#Show_tab'" target="_blank">https://mapwarper.net/layers/{{mwid1mosaic}}#Show_tab</a> <br>
            <b>Map Services</b><br>
	• KML: Download KML file (for use in Google Earth, etc) <a :href="'https://mapwarper.net/layers/' + mwid1mosaic + '.kml'" target="_blank">https://mapwarper.net/layers/{{mwid1mosaic}}.kml</a><br>
	• WMS: WMS Capabilities URL (for JOSM OpenStreetMap Editor ) <a :href="'https://mapwarper.net/mosaics/wms/' + mwid1mosaic + '?request=GetCapabilities&service=WMS&version=1.1.1'" target="_blank">https://mapwarper.net/mosaics/wms/{{mwid1mosaic}}?request=GetCapabilities&service=WMS&version=1.1.1</a><br>
	• Tiles (Google/OSM scheme): <a :href="'https://mapwarper.net/mosaics/tile/' + mwid1mosaic + '/0/0/0.png'" target="_blank">https://mapwarper.net/mosaics/tile/{{mwid1mosaic}}/{z}/{x}/{y}.png</a><br>
    </p>
     <p v-else> This map doesn't have <i>mapwarper.net</i> links. </p>

             </b-col>
  </b-row>
</b-container>

        <!-- <div class="mj__btn mj__copy-btn" @click="copyToClipboard(shareLink)">
            <template v-if="!copied">
                Copy link
            </template>
            <template v-else>
                Copied
            </template>
        </div> -->
    </div>
</template>


<script>
export default {
  name: 'gisModal',
  data () {
    return {
      location: window.location,
      sharearea: false,
      link: `<iframe src="${this.$store.state.shareLink}" style='border:none; width: 100%; height:800px; padding: 0; margin: 0;'></iframe>`,
      copied: false
    }
  },
  
  computed: {
      mapname2 () {
          return this.$store.state.currentMaps[2].title
      },
      mapyear2 () {
          return this.$store.state.currentMaps[2].year
      },
    mwid2 () {
         if (this.$store.state.currentMaps[2].meta != null) {
         if (this.$store.state.currentMaps[2].meta.map_id.includes("link.mapwarpermap") == true) {
      var res = this.$store.state.currentMaps[2].meta.map_id.replace("link.mapwarpermap", "");
      return res}
      else {
return 0
      }}
      else {
          return 0
          //try initmaps
      }
    },
       mwid2mosaic () {
         if (this.$store.state.currentMaps[2].meta != null) {
         if (this.$store.state.currentMaps[2].meta.map_id.includes("mosaic.mapwarperlayer") == true) {
      var res = this.$store.state.currentMaps[2].meta.map_id.replace("mosaic.mapwarperlayer", "");
      return res}
      else {
return 0
      }}
      else {
          return 0
          //try initmaps
      }
    },
    mapname1 () {
          return this.$store.state.currentMaps[1].title
      },
      mapyear1 () {
          return this.$store.state.currentMaps[1].year
      },
    mwid1 () {
          if (this.$store.state.currentMaps[1].meta != null) {
         if (this.$store.state.currentMaps[1].meta.map_id.includes("link.mapwarpermap") == true) {
      var res = this.$store.state.currentMaps[1].meta.map_id.replace("link.mapwarpermap", "");
      return res}
      else {
return 0
      }}
      else {
   //try initmaps
   return 0
      }
    },
      mwid1mosaic () {
          if (this.$store.state.currentMaps[1].meta != null) {
         if (this.$store.state.currentMaps[1].meta.map_id.includes("mosaic.mapwarperlayer") == true) {
      var res = this.$store.state.currentMaps[1].meta.map_id.replace("mosaic.mapwarperlayer", "");
      return res}
      else {
return 0
      }}
      else {
   //try initmaps
   return 0
      }
    },
    encodedLink () {
      return encodeURIComponent(this.shareLink)
    },
    soclink () {
      return this.shareLink.replace('#', '%23').split('&').join('%26')
    }
  },
  methods: {
      
    copyToClipboard (str) {
      const el = document.createElement('textarea')
      el.value = str
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.copied = true
    }
  }
}
</script>

