<template>
    <div class="mj__inner-content mj__content-grid">
        <div class="row">
            Mapjunction Session Player
        </div>

          <button @click="setURL('mjplayer?lat=42.3531000&lng=-71.0663000&map1=link.mapwarpermap32092&map2=link.mapwarpermap18587&zoom=14.0000000&mode=overlay&b=0&p=0')">setUrl1</button>
          <br>
         <button @click="setURL('mjplayer?lat=42.3531000&lng=-71.0663000&map1=link.mapwarpermap32092&map2=link.mapwarpermap18587&zoom=16.0000000&mode=overlay&b=0&p=0')">setUrl2</button>
         <button @click="startPlay()">startPlay</button>

    </div>
</template>

<script>
export default {
  name: 'List',
  data () {
    return {
      selectedType: 'collection',
      articleName: '',
      articleNewName: '',
      channelName: '',
      channelNewName: ''
    }
  },
  computed: {

  },
  created () {

  },
  methods: {
    // flyTo (lat, lng, cropX, cropY, zoom) {
    //   let center = [lng, lat]
    //   this.$eventBus.$emit('flyTo', center)
    // },
    startPlay () {
      alert('here will be implementation of MJPLAYER')
    },
    routerParams () {
      this.routerQuery = this.$store.state.routerParams
    },
    setURL (str) {
      this.$eventBus.$emit('updatePlayer', str)
    }

  }
}
</script>

<style lang="scss" scoped>
    .flex {
        display: flex;
    }
       .mj__content-grid {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 0 0 auto;
    }
   .row {
        margin-bottom: 20px;
    }
</style>
