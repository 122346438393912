import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../components/Main'
import Content from '../components/profile/Content'
import StoryCreate from '../components/profile/StoryCreate'
import MJPlayer from '../components/MJPlayer'
import Profile from '../components/profile/Profile'
import Cities from '../components/profile/Cities'
import List from '../components/profile/List'
import Apitest from '../components/Apitest'
import CollectionsList from '../components/profile/CollectionsList'
import PlaceEntityList from '../components/profile/PlaceEntityList'
import MyCollectionsList from '../components/profile/MyCollectionsList'
import MyChannelsList from '../components/profile/MyChannelsList'
import Collection from '../components/profile/Collection'
import CollectionCreate from '../components/profile/CollectionCreate'
import CollectionEdit from '../components/profile/CollectionEdit'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/content/collections/create',
      name: 'CollectionCreate',
      component: CollectionCreate,
      children: []
    },
    {
      path: '/content',
      name: 'Content',
      component: Content,
      children: [{
        path: 'cities',
        name: 'Cities',
        component: Cities,
        children: []
      },
      {
        path: 'cities/:id',
        name: 'City',
        component: List,
        children: []
      },
      {
        path: 'test',
        name: 'Apitest',
        component: Apitest,
        children: []
      },
      {
        path: 'collections',
        name: 'CollectionsList',
        component: CollectionsList,
        children: []
      },
      {
        path: 'collections/:id',
        name: 'PlaceCollectionsList',
        component: PlaceEntityList,
        children: [],
        props: { entity: 'collections' }
      },
      {
        path: 'channels/:id',
        name: 'PlaceChannelsList',
        component: PlaceEntityList,
        children: [],
        props: { entity: 'channels' }
      },
      {
        path: 'stories/:id',
        name: 'PlaceStoriesList',
        component: PlaceEntityList,
        children: [],
        props: { entity: 'articles' }
      },
      {
        path: 'places/:id',
        name: 'PlacePlacesList',
        component: PlaceEntityList,
        children: [],
        props: { entity: 'places' }
      }
      ]
    },
    {
      path: '/content/collection/:id',
      name: 'Collection',
      component: Collection,
      children: []
    },
    {
      path: '/content/collections/edit/:id',
      name: 'CollectionEdit',
      component: CollectionEdit,
      children: []
    },
    {
      path: '/content/profile/',
      name: 'Profile',
      component: Profile,
      children: [
        {
          path: '/content/mycollections',
          name: 'MyCollectionsList',
          component: MyCollectionsList,
          children: []
        },
        {
          path: '/content/myplaces',
          name: 'MyPlacesList',
          component: MyCollectionsList,
          children: []
        },
        {
          path: '/content/mychannels',
          name: 'MyChannelssList',
          component: MyChannelsList,
          children: []
        },
        {
          path: '/content/mystories',
          name: 'MyStoriesList',
          component: MyCollectionsList,
          children: []
        }
      ]
    },
    {
      path: '/content/story/create',
      name: 'StoryCreate',
      component: StoryCreate,
      children: []
    },
    {
      path: '/mjplayer',
      name: 'MJPlayer',
      component: MJPlayer,
      children: []
    },
    {
      path: '/content2',
      name: 'content2',
      component: {
        Main
      }
    },
    {
      path: '/start/map/:startMapId',
      alias: '/'
    }
  ]
})
