<template>
    <div class="mj__sbs-maps" v-bind:class="['mj__sbs-maps--' + orientation, {moving: nowMoving}]">
        <div id="map-bg" class="map" ref="mapBg"></div>
        <div id="map" class="map"></div>
        <div id="map-overlay" class="map" ref="mapOverlay"></div>
        <div class="mj__map-clipper" ref="clipper" draggable="true" v-intro="'<b>4-Way Slider</b><br><br> Your power tool for comparing maps. <br><br>Left/right moves the boundary. Up/down changes transparency.'" v-intro-step="2"></div>
        <Tilebar map-id="2" class="mj__tilebar" />
        <Tilebar map-id="1" class="mj__tilebar mj__tilebar--right"/>
    </div>
</template>

<script>
import Mapbox from 'mapbox-gl-vue'
import Tilebar from './Tilebar'
let mapboxgl = require('mapbox-gl')
let MapboxGeocoder = require('@mapbox/mapbox-gl-geocoder')
let syncMove = require('mapbox-gl-sync-move')
let Draggable = require('draggable')

const mapboxToken = 'pk.eyJ1IjoiZ3JlZ2NvY2tyb2Z0IiwiYSI6ImNpamVnMGxvcDAwYmx1cW0wNmd5djZraDQifQ.C_Gg9TBDeBvb2m_4uOO8SA'

export default {
  name: 'Mapwrap',
  props: ['type'],
  components: {
    Mapbox,
    Tilebar
  },
  data () {
    return {
      token: mapboxToken,
      map: {}
    }
  },
  computed: {
    gcp () {
      return this.$store.state.gcp
    },
    fitTo () {
      return this.$store.state.fitTo
    },
    lat () {
      return this.$store.state.center.lng || -71.0663
    },
    long () {
      return this.$store.state.center.lat || 42.3531
    },
    zoom () {
      return this.$store.state.zoom || 14
    },
    initMap1 () {
      return this.$store.state.initMap1 || {}
    },
    initMap2 () {
      return this.$store.state.initMap2 || {}
    },
    clipperX () {
      let clipperX = this.$store.state.routerParams.clipperX

      if (clipperX) {
        let x = parseFloat(clipperX)

        if (x <= 1) {
          x = x * window.innerWidth
        }

        return parseInt(x)
      }
    },
    clipperY () {
      let clipperY = this.$store.state.routerParams.clipperY

      if (clipperY) {
        let y = parseFloat(clipperY)

        if (y <= 1) {
          y = y * window.innerHeight
        }

        return parseInt(y)
      }
    },
    pitch () {
      return this.$store.state.routerParams.p || 0
    },
    bearing () {
      return this.$store.state.routerParams.b || 0
    },
    orientation () {
      return this.$store.state.mapMode
    },
    currentTile () {
      return this.$store.state.currentTile
    },
    contentBoxShown () {
      return this.$store.state.contentBoxShown
    },
    tilebarsVisibility () {
      return this.$store.state.tilebarsVisibility
    },
    initOverlayData () {
      return this.$store.state.initOverlayData
    },
    nowMoving () {
      return this.$store.state.nowMoving
    }
  },
  watch: {
    contentBoxShown (from, to) {
      let centerX = this.windowWidth / 2

      this.draggbleClipper.destroy()
      this.syncMaps()
      this.initDraggble()

      if (!to) {
        centerX = this.windowWidth / 4
      }

      this.clippedMap.style.clipPath = `inset(0 ${centerX}px 0 0)`
      this.clippedMap.style.webkitClipPath = `inset(0 ${centerX}px 0 0)`
    },
    orientation (from, to) {
      this.$nextTick(() => {
        this.syncMaps(from, to)
      })
    },
    gcp (from, to) {
      if (this.map1 && this.map2 && this.gcp) {
        let maps = [this.map1, this.map2]

        for (let marker in this.markers) {
          this.markers[marker].remove()
        }

        for (let mapIdx in maps) {
          let map = maps[mapIdx]
          let gcp = this.gcp
          for (let point in gcp) {
            let lat = gcp[point].attributes.lat
            let lon = gcp[point].attributes.lon
            let marker = new mapboxgl.Marker()
              .setLngLat([lon, lat])
              .addTo(map)
            this.markers.push(marker)
          }
        }
      }
    }
  },
  created () {
    localStorage.setItem('MapboxAccessToken', mapboxToken)
    this.$eventBus.$on('setTile', this.setTile)
    this.zoomTimeout = 0
    this.moveTimeout = 0
    this.windowHeight = window.innerHeight
    this.windowWidth = window.innerWidth
    this.markers = []
  },
  mounted () {
    setTimeout(() => {
      let _this = this
      let style = 'https://api.maptiler.com/maps/positron/style.json?key=ZKDzo6z50TrARb5Y67FR'

      let mapOptions = {
        container: 'map',
        center: [_this.lat, _this.long],
        zoom: _this.zoom,
        style: style,
        bearing: this.bearing,
        pitch: this.pitch,
        attributionControl: false
      }

      let mapBgOptions = {
        container: 'map-bg',
        center: [_this.lat, _this.long],
        zoom: _this.zoom,
        style: style,
        bearing: this.bearing,
        pitch: this.pitch,
        attributionControl: false

      }

      let mapOverlayOptions = {
        container: 'map-overlay',
        center: [_this.lat, _this.long],
        zoom: _this.zoom,
        style: 'mapbox://styles/mapbox/empty-v8',
        bearing: this.bearing,
        pitch: this.pitch,
        attributionControl: false
      }

      mapboxgl.accessToken = _this.token

      let map = new mapboxgl.Map(mapOptions)
      let mapOverlay = new mapboxgl.Map(mapOverlayOptions)
      let mapBg = new mapboxgl.Map(mapBgOptions)

      mapOverlay.addControl(new MapboxGeocoder({
        accessToken: this.token
      }))

      map.addControl(new MapboxGeocoder({
        accessToken: this.token
      }))

      map.addControl(new mapboxgl.AttributionControl(), 'bottom-left')
      // mapOverlay.addControl(new mapboxgl.AttributionControl(), 'bottom-left');

      syncMove(map, mapOverlay)
      syncMove(map, mapBg)

      map.addControl(new mapboxgl.NavigationControl())
      mapOverlay.addControl(new mapboxgl.NavigationControl())

      map.addControl(new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      }))

      mapOverlay.addControl(new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      }))

      this.$store.commit('setMoving', false)
      this.clipperClientRect = this.$refs.clipper.getBoundingClientRect()
      this.clippedMap = this.$refs.mapOverlay.getElementsByTagName('canvas')[0]

      if (!this.orientation && this.orientation === 'overlay' || this.orientation === 'vertical') {
        this.clippedMap.style.clipPath = 'inset(0 50% 0 0)'
        this.clippedMap.style.webkitClipPath = 'inset(0 50% 0 0)'
      }

      this.map1 = map
      window.basemap = map
      this.map2 = mapOverlay
      this.map3 = mapBg

      let maps = [this.map1, this.map2]

      for (let mapIdx in maps) {
        let map = maps[mapIdx]

        map.on('load', () => {
          this.$store.commit('setZoom', map.getZoom())
          this.$store.commit('setCenter', map.getCenter())
          this.$store.commit('setBounds', map.getBounds())
          this.$store.commit('updateTilesUrls')
          this.$store.dispatch('getMapsByCoords').then(() => {
            this.$store.commit('setTilebarsVisible', { mapId: 2, visibility: true })
            this.$store.commit('setTilebarsVisible', { mapId: 1, visibility: true })
          })

          if (mapIdx === '1') {
            setTimeout(() => {
              let initMapData = this.initMap2

              let prefix = ' Left Map:'
              var attr = initMapData.meta.attribute
              if (!attr || attr == null) { attr = '' }

              if (initMapData.meta.alink != null) { attr = prefix + " <a href='" + initMapData.meta.alink + "'  target=\"_blank\">  " + attr + '</a>' }
              if (initMapData.meta.alink == null) {
                if (initMapData.meta.attribute == null) { attr = '' } else {
                  attr = prefix + ' ' + initMapData.meta.attribute
                }
              }
              let mapId = '2'
              let options = {
                mapId: mapId,
                attributions: attr,
                tile: initMapData.tiles ? initMapData.tiles : initMapData.tile,
                wms: initMapData.wms
              }

              this.initMap2.mapIdx = mapId
              this.$store.commit('setCurrentMap', this.initMap2)
              this.$eventBus.$emit('setTile', options)
            }, 0)
          } else {
            setTimeout(() => {
              let initMapData = this.initMap1

              let prefix = ' Right Map:'
              var attr = initMapData.meta.attribute
              if (!attr || attr == null) { attr = '' }
              if (initMapData.meta.alink != null) { attr = prefix + " <a href='" + initMapData.meta.alink + "'  target=\"_blank\">" + attr + '</a>' }
              if (initMapData.meta.alink == null) {
                if (initMapData.meta.attribute == null) { attr = '' } else {
                  attr = prefix + ' ' + initMapData.meta.attribute
                }
              }

              let mapId = '1'
              let options = {
                mapId: mapId,
                attributions: attr,
                tile: (initMapData.tiles ? initMapData.tiles : initMapData.tile),
                wms: initMapData.wms
              }

              this.initMap1.mapIdx = mapId

              this.$store.commit('setCurrentMap', this.initMap1)
              this.$eventBus.$emit('setTile', options)
            }, 0)
          }
        })

        map.on('zoom', () => {
          clearTimeout(this.zoomTimeout)
          this.zoomTimeout = setTimeout(() => {
            this.$store.commit('setZoom', map.getZoom())
            this.$store.commit('setBounds', map.getBounds())

            this.dispatchTimeout = setTimeout(() => {
              this.$store.dispatch('getMapsByCoords')
            }, 300)
            if (this.tilebarsVisibility[1] || this.tilebarsVisibility[2]) {
              this.$store.commit('updateTilesUrls')
            }
          }, 300)
        })

        map.on('move', () => {
          clearTimeout(this.moveTimeout)
          clearTimeout(this.dispatchTimeout)

          this.moveTimeout = setTimeout(() => {
            this.$store.commit('setCenter', map.getCenter())
            this.$store.commit('setBounds', map.getBounds())
            this.$store.commit('setPitch', map.getPitch())
            this.$store.commit('setBearing', map.getBearing())

            this.dispatchTimeout = setTimeout(() => {
              this.$store.dispatch('getMapsByCoords')
            }, 300)
            if (this.tilebarsVisibility[1] || this.tilebarsVisibility[2]) {
              this.$store.commit('updateTilesUrls')
            }
          }, 300)
        })

        window.addEventListener('resize', () => {
          clearTimeout(this.resizeTimeout)

          this.resizeTimeout = setTimeout(() => {
            this.draggbleClipper.destroy()
            this.windowHeight = window.innerHeight
            this.windowWidth = window.innerWidth
            this.initDraggble()
            this.syncMaps()
            this.clippedMap.style.clipPath = `inset(0 ${this.windowWidth / 2}px 0 0)`
            this.clippedMap.style.webpkitClipPath = `inset(0 ${this.windowWidth / 2}px 0 0)`
          }, 200)
        })

        this.$store.commit('setMoving', false)
      };

      this.$eventBus.$on('flyTo', (center) => {
        map.flyTo({
          center: center
        })
      })

      this.$eventBus.$on('updatePlayer', (str) => {
        this.$router.push(str)
        let urlParams = this.$route.query

        let coords = {
          lat: urlParams.lat,
          lng: urlParams.lng
        }

        map.flyTo({
          center: coords,
          zoom: urlParams.zoom
        })
      })
      this.$eventBus.$on('fitBounds', (bbox) => {
        let bounds = bbox
        let fitTo = []

        if (bounds.northeast && bounds.southwest) {
          fitTo.push([bounds.southwest.lng, bounds.southwest.lat])
          fitTo.push([bounds.northeast.lng, bounds.northeast.lat])
        }

        this.map2.fitBounds(fitTo, {
          padding: { top: 100, bottom: 100, left: 50, right: 50 }
        })
      })

      this.initDraggble()
      this.setOpacity()

      if (this.fitTo.length) {
        this.map2.fitBounds(this.fitTo, {
          padding: { top: 100, bottom: 100, left: 50, right: 50 }
        })
      }

      document.addEventListener('click', this.clickHandler)
    }, 0)
  },
  methods: {
    clickHandler (event) {
      if (!event.target.closest('.mj__tilebar-wrap') &&
                    !event.target.closest('.mapboxgl-ctrl') &&
                    !event.target.closest('.mj__nav')) {
        this.$store.commit('setMoving', !this.nowMoving)
      }
    },
    newLayer (options) {
      if (options && options.mapId) {
        console.log(options)
        let map = this['map' + options.mapId]
        if (options.tile) {
          map.addSource('tileLayer', {
            'type': 'raster',
            'tiles': [this.currentTile],
            'tileSize': 256,
            'attribution': options.attributions
          })
          console.log('starting to listen styledata')
          map.on('styledata', () => {
            const waiting = () => {
              if (!map.isStyleLoaded()) {
                setTimeout(waiting, 200)
              } else {
                if (!map.getLayer('test-layer')) {
                  map.addLayer({
                    'id': 'test-layer',
                    'type': 'raster',
                    'source': 'tileLayer'

                  })
                }
              }
            }
            waiting()
          })

          if (options.mapId == '2') {
            let map0 = this['map1']

            console.log('addingGeojson')
            const layer = map0.getSource('attribution-layer')
            if (layer) {
              map0.removeLayer('attribution-layer')
              map0.removeSource('attribution-layer')
            }

            map0.addLayer({
              'id': 'attribution-layer',
              'type': 'circle',
              'source': {

                'type': 'geojson',
                'data': {
                  'type': 'Feature',
                  'properties': {},
                  'geometry': null
                }
              }
            })
            // &copy;
            map0.style.sourceCaches['attribution-layer']._source.attribution = ' ' + options.attributions
          }
        }
      }
    },
    syncMaps (from, to) {
      let x = 0

      this.map1 && this.map1.resize()
      this.map2 && this.map2.resize()
      this.map3 && this.map3.resize()

      if (from === 'overlay') {
        x = this.clipperX ? this.clipperX : window.innerWidth / 2

        if (this.contentBoxShown) {
          x = window.windowWidth / 4
        }
      }

      if (this.clippedMap) {
        if (this.orientation === 'overlay') {
          this.clipCanvas('', x)
        } else if (this.orientation === 'vertical') {
          this.clippedMap.style.clipPath = `inset(0 50% 0 0)`
          this.clippedMap.style.webkitClipPath = `inset(0 50% 0 0)`
        } else {
          this.clippedMap.style.clipPath = `inset(0 0 50% 0)`
          this.clippedMap.style.webkitClipPath = `inset(0 0 50% 0)`
        }

        this.clippedMap.style.opacity = 1
      }
    },

    hideBars () {
      this.$store.commit('setTilebarsVisible', { mapId: 1, visibility: false })
      this.$store.commit('setTilebarsVisible', { mapId: 2, visibility: false })
    },

    handleMove (el, x, y, e) {
      let stickingOfset = 30

      if (this.tilebarsVisibility[1] || this.tilebarsVisibility[2]) {
        this.hideBars()
      }

      this.clipCanvas(el, x, y, e)
      this.setOpacity(el, x, y, e)

      if (x) {
        this.$store.commit('clipperX', (x / window.innerWidth).toFixed(7))
      }

      if (y > this.windowHeight / 2 - stickingOfset && y < this.windowHeight / 2 + stickingOfset) {
        this.draggbleClipper.set(null, this.windowHeight / 2)
      }
    },
    clipCanvas (el, x, y, e) {
      let windowWidth = this.contentBoxShown ? this.windowWidth / 2 : this.windowWidth

      if (!x) {
        windowWidth = windowWidth / 2
        x = 0
      }

      this.clippedMap.style.clipPath = `inset(0 ${windowWidth - x}px 0 0)`
      this.clippedMap.style.webkitClipPath = `inset(0 ${windowWidth - x}px 0 0)`
    },
    setOpacity (el, x, y, e) {
      let opacity = 1
      let topHalf = this.windowHeight / 2
      let bottomHart = this.windowHeight - this.windowHeight / 2

      if (y > bottomHart) {
        opacity = 1 - (y - bottomHart) / bottomHart
      } else {
        opacity = y / topHalf
      }

      if (y) {
        this.$store.commit('clipperY', (y / window.innerHeight).toFixed(7))
      }

      this.clippedMap.style.opacity = opacity
    },
    getCenter (options) {
      if (options && options.mapId) {
        let map = this['map' + options.mapId]
        this.$store.commit('setCenter', map.getCenter())
      }
    },
    setTile (options) {
      console.log(options)
      if (options && options.mapId) {
        this.$store.commit('setCurrentTile', options.tile)
        let map = this['map' + options.mapId]
        let mapwarperMapId = options.tile && checkGcpMapId(options.tile)

        const source = map.getSource('tileLayer')

        if (this.$route.query.gcp) {
          this.$store.dispatch('getGCP', mapwarperMapId)
        }

        if (source) {
          map.removeLayer('test-layer')
          map.removeSource('tileLayer')
        }
        console.log('newLayer', options)
        this.newLayer(options)

        function checkGcpMapId (tilename) {
          let tilenameParts = tilename.split('/')
          let mapIdIndexInArray = tilenameParts.indexOf('tile') + 1 // take next param from url after '/tile/'

          if (mapIdIndexInArray) {
            return tilenameParts[mapIdIndexInArray]
          }
        }
      }
    },
    initDraggble () {
      let widthLimit = this.contentBoxShown ? window.innerWidth / 2 : window.innerWidth
      let heightLimit = this.clipperY ? this.clipperY : window.innerHeight / 2

      if (this.clipperX) {
        widthLimit = this.clipperX
      } else {
        widthLimit = widthLimit / 2
      }

      let dragOptions = {
        onDrag: this.handleMove,
        limit: {
          x: [0, (this.contentBoxShown ? window.innerWidth / 2 : window.innerWidth)],
          y: [0, window.innerHeight]
        }
      }

      this.draggbleClipper = new Draggable(this.$refs.clipper, dragOptions)
      this.draggbleClipper.set(widthLimit, heightLimit)

      if (!this.orientation || this.orientation === 'overlay') {
        this.clipCanvas(this.$refs.clipper, this.clipperX)
        this.setOpacity(this.$refs.clipper, this.clipperX, this.clipperY)
      }
    }
  }
}
</script>
