<template>
    <span>
        <template v-if="mapsYears.min && mapsYears.max">
            {{ mapsYears.min }} - {{ mapsYears.max }}
        </template>
        <template v-else>
            unknown years
        </template>
    </span>
</template>

<script>
export default {
  name: 'MapsYears',
  props: ['maps'],
  computed: {
    mapsYears () {
      let minAndMaxYears = {}
      for (let map in this.maps) {
        let year = this.maps[map].year

        if (year) {
          minAndMaxYears.min = minAndMaxYears.min ? minAndMaxYears.min : year
          minAndMaxYears.max = minAndMaxYears.max ? minAndMaxYears.max : year

          minAndMaxYears.min = (year < minAndMaxYears.min) ? year : minAndMaxYears.min
          minAndMaxYears.max = (year > minAndMaxYears.max) ? year : minAndMaxYears.max
        }
      }

      return minAndMaxYears
    }
  }
}
</script>
