<template>
    <div class="mj__inner-content mj__item">
        <div class="mj__header" v-bind:style="'background-image: url(https://alpha.mapjunction.com/images/boston.jpg)'">
            <div class="mj__header-content">
                <Header />
                <div class="mj__collection-head">
                    <label for="uploader" class="mj__uploader mj__uploader--collection-img">
                        <span class="mj__uploader-text" v-if="!uploadingPhotoToCollection">Click here<br>to upload image</span>
                        <span class="mj__uploader-preloader" v-else></span>
                        <div class="mj__collection-image mj__collection-image--uploader" v-if="collectionImage && collectionImage.data" v-bind:style="'background-image: url(' + (collectionImage.data.link)"></div>
                        <input type="file" class="mj__file-input" id="uploader" ref="fileinput" @change="uploadPhoto()">
                    </label>

                    <div class="mj__collection-texts">
                        <div class="mj__collection-title">
                            COLLECTION
                        </div>
                        <label class="mj__label mj__collection-name" for="title">
                            <input type="text" placeholder="Collection title" id="title" v-model="title">
                        </label>
                        <div class="mj__label mj__collection-subtitle" v-if="user">
                            {{ user.name}} {{user.lastname}} - <router-link :to="'/content/channel/channelId'">@channel</router-link>
                        </div>
                        <div class="mj__collection-subtitle">
                            {{ (currentCollection.maps ? currentCollection.maps.length : 0) }} maps
                        </div>
                        <button class="mj__button" @click="createCollection()">Create collection</button>
                    </div>
                </div>

            </div>
            <div class="mj__header-gradient"></div>
        </div>
    </div>
</template>

<script>
import Header from './Header'
export default {
  name: 'CollectionCreate',
  data () {
    return {
      uploadingPhotoToCollection: false,
      title: ''
    }
  },
  components: {
    Header
  },
  watch: {
    collectionImage (to, from) {
      if (from !== to) {
        this.uploadingPhotoToCollection = false
      }
    },
    lastCreatedCollection (to, from) {
      if (to.data.status == 'ok') {
        this.$router.push('/content/collections/edit/' + to.data.id)
      }
    }

  },
  computed: {
    selectedPlace () {
      return this.$store.state.selectedPlace
    },
    user () {
      return this.$store.state.user
    },
    currentCollection () {
      return this.$store.state.currentCollection || {}
    },
    collectionToCreateTmp () {
      return this.$store.state.collectionToCreateTmp || {}
    },
    collectionImage () {
      return this.collectionToCreateTmp.image
    },
    lastCreatedCollection () {
      return this.$store.state.lastCreatedCollection
    }
  },
  created () {
    this.$store.commit('toggleContentBox', true)

    if (this.$route.params.id) {
      this.$store.dispatch('getCollection', { id: this.$route.params.id })
    }
  },
  methods: {
    uploadPhoto () {
      if (!this.$refs.fileinput) {
        return
      }

      let options = {
        file: this.$refs.fileinput.files[0],
        type: 'collection',
        isNew: true
      }

      this.uploadingPhotoToCollection = true
      this.$store.dispatch('uploadPhotoToCollection', options)
    },
    createCollection () {
      if (!this.title) {
        return
      }

      let options = {
        is_public: 1,
        name: this.title,
        background_image: this.collectionImage.data.id
      }

      this.$store.dispatch('createCollection', options)
    }
  }
}
</script>
<style lang="scss" scoped>
    .flex {
        display: flex;
    }
    .uploader-test, .mj__content-grid {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 0 0 auto;
    }
    .row {
        margin-bottom: 20px;
    }
</style>
