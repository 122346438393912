<template>
    <div class="mj__inner-content mj__item">
        <div class="mj__header" v-bind:style="'background-image: url(https://alpha.mapjunction.com/images/boston.jpg)'">
            <div class="mj__header-content">
                <Header />
                <div class="mj__collection-head" v-if="currentCollection">
                    <label for="uploader" class="mj__uploader mj__uploader--collection-img">
                        <span class="mj__uploader-text" v-if="!uploadingPhotoToCollection">Click here<br>to upload image</span>
                        <span class="mj__uploader-preloader" v-else></span>
                        <div class="mj__collection-image mj__collection-image--uploader" v-if="currentCollection && currentCollection.background_image" v-bind:style="'background-image: url(' + (currentCollection.background_image)"></div>
                        <input type="file" class="mj__file-input" id="uploader" ref="fileinput" @change="uploadPhoto()">
                    </label>

                    <div class="mj__collection-texts">
                        <div class="mj__collection--wrap">
                            <div class="mj__entity">
                                <div class="mj__entity-title">
                                COLLECTION
                                </div>
                                <Saverstatus :status="entitySaveStatus"/>
                            </div>
                        </div>
                        <label class="mj__label mj__collection-name" for="title">
                            <input type="text" placeholder="Collection title" id="title" v-model="currentCollection.title" @blur="saveCollection()">
                        </label>
                        <div class="mj__label mj__collection-subtitle" v-if="user">
                            {{ user.name}} {{user.lastname}} -  <router-link class="mj__channel-link" :to="'/content/channel/channelId'">@channel</router-link>
                        </div>
                        <div class="mj__collection-subtitle">
                            <MapsYears :maps="currentCollection.maps"/>, {{ (currentCollection.maps ? currentCollection.maps.length : 0) }} maps
                        </div>
                    </div>
                </div>
                <v-client-table class="mj__table" :columns="columns" :data="currentCollection.maps || []" :options="options">
                    <a slot="mapid" v-bind:class="props.row.id" slot-scope="props" class="id-row">
                        <i class="icon icon-delete-button" @click="removeMapFromCollection(props.row.id)"></i>
                    </a>
                    <a slot="flyTo" slot-scope="props" class="">
                        <i class="icon icon-map-placeholder"></i>
                    </a>
                </v-client-table>
                <div class="mj__button mj__button--addmap" @click="showAddMapModal()">Add map</div>
            </div>
            <div class="mj__header-gradient"></div>
        </div>
    </div>
</template>

<script>
import Header from './Header'
import Saverstatus from './Saverstatus'
import MapsYears from './MapsYears'
export default {
  name: 'CollectionCreate',
  data () {
    return {
      uploadingPhotoToCollection: false,
      columns: ['flyTo', 'year', 'title', 'area', 'mapid'],
      options: {
        headings: {
          mapid: '',
          flyTo: '',
          year: 'Year',
          title: 'Title',
          area: 'Area'
        },
        sortable: [ 'area', 'title', 'year'],
        filterable: ['area', 'title', 'year']
      },
      saveStatus: null
    }
  },
  components: {
    Header,
    Saverstatus,
    MapsYears
  },
  watch: {
    collectionImage (to, from) {
      if (from !== to) {
        if (to.data && to.data.link) {
          this.currentCollection.background_image = to.data.link
          this.saveCollection()
        }
      }

      this.uploadingPhotoToCollection = false
    },
    entitySaveStatus (newState) {

    }
  },
  computed: {
    selectedPlace () {
      return this.$store.state.selectedPlace
    },
    entitySaveStatus () {
      return this.$store.state.saveStatus
    },
    user () {
      return this.$store.state.user
    },
    currentCollection () {
      return this.$store.state.currentCollection || {}
    },
    collectionToCreateTmp () {
      return this.$store.state.collectionToCreateTmp || {}
    },
    collectionImage () {
      return this.collectionToCreateTmp.image
    },
    lastCreatedCollection () {
      return this.$store.state.lastCreatedCollection
    },
    mapsCollection () {
      return this.$store.state.mapsInCurrentCollection
    }
  },
  created () {
    this.$store.commit('toggleContentBox', true)

    if (this.$route.params.id) {
      this.$store.dispatch('getCollection', { id: this.$route.params.id })
    }

    this.$eventBus.$on('addMapToCollection', (map) => {
      this.$store.commit('addMapsToCollectionObj', map)
      this.saveCollection()
    })
  },
  methods: {
    uploadPhoto () {
      if (!this.$refs.fileinput) {
        return
      }

      let options = {
        file: this.$refs.fileinput.files[0],
        type: 'collection',
        isNew: true
      }

      this.uploadingPhotoToCollection = true
      this.$store.dispatch('uploadPhotoToCollection', options)
    },
    saveCollection () {
      if (!this.currentCollection.title) {
        return
      }

      let options = {
        is_public: 1,
        id: this.currentCollection.id,
        title: this.currentCollection.title,
        maps: {
          add: this.mapsCollection
        }
      }

      if (this.collectionImage) {
        options.background_image = this.collectionImage.data.id
      }

      this.$store.dispatch('updateCollection', options)
    },
    showAddMapModal () {
      this.$store.commit('showModal', { type: 'addMap' })
    },
    removeMapFromCollection (id) {
      let options = {
        id: this.currentCollection.id,
        maps: {
          remove: [id]
        }
      }

      this.$store.dispatch('updateCollection', options)
      this.currentCollection.maps.splice(this.currentCollection.maps.indexOf(id), 1)
    }
  }
}
</script>
<style lang="scss" scoped>
    .flex {
        display: flex;
    }
    .uploader-test, .mj__content-grid {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 0 0 auto;
    }
    .row {
        margin-bottom: 20px;

        .col-md-12 {
            display: flex;
            justify-content: space-between;
        }
    }
</style>
