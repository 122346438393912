<template>
    <div class="mj__header-navbar">
        <div class="mj__arrows mj__arrows--left" @click="$router.go(-1)"><</div>
        <div class="mj__arrows mj__arrows--right"  @click="$router.go(1)">></div>
        <input type="text" class="mj__header-search" placeholder="Search...">
        <div class="mj__alpha-logo"></div>
        <a href="/account/" class="mj__sign-up" v-if="!user">Sign up</a>
        <div class="mj__sign-up" v-else>
            {{ user.name }}
        </div>
        <router-link to="/content/mycollections/" v-if="!isprofile && user" class="mj__toggle-button mj__sign-up">Profile</router-link>
        <template v-if="currentPlace && currentPlace.place.id && currentPlace.place.title">
            <router-link :to="'/content/cities/' + currentPlace.place.id" v-if="isprofile" class="mj__toggle-button mj__sign-up">Back to {{ currentPlace.place.title.split(',')[0] }}</router-link>
        </template>
        <template v-else>
            <router-link to="/content/cities/5b0add339cb449bf27d7199b" v-if="isprofile" class="mj__toggle-button mj__sign-up">Back to Boston</router-link>
        </template>
    </div>
</template>

<script>
export default {
  name: 'Header',
  props: ['isprofile'],
  computed: {
    user () {
      return this.$store.state.user
    },
    currentPlace () {
      return this.$store.state.currentPlace
    }
  }
}
</script>
