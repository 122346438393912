<template>
    <div class="mj__sbs-maps" v-bind:class="'mj__sbs-maps--' + this.orientation">
        <div id="map" class="map map--half  map--first"></div>
        <div id="second-map" class="map map--half map--second"></div>
        <Tilebar map-id="2" class="mj__tilebar"/>
        <Tilebar map-id="1" class="mj__tilebar mj__tilebar--right"/>
    </div>
</template>

<script>
import Mapbox from 'mapbox-gl-vue'
import Tilebar from './Tilebar'
let mapboxgl = require('mapbox-gl')
var syncMove = require('mapbox-gl-sync-move')
let MapboxGeocoder = require('@mapbox/mapbox-gl-geocoder')

const mapboxToken = 'pk.eyJ1IjoiZ3JlZ2NvY2tyb2Z0IiwiYSI6ImNpamVnMGxvcDAwYmx1cW0wNmd5djZraDQifQ.C_Gg9TBDeBvb2m_4uOO8SA'

export default {
  name: 'Sidebyside',
  props: ['orientation'],
  components: {
    Tilebar
  },
  data () {
    return {
      token: mapboxToken,
      map: {},
      lat: -71.0663,
      long: 42.3531,
      zoom: 16
    }
  },
  watch: {
    $route (to, from) {
      setTimeout(() => {
        this.syncMaps()
      }, 0)
    }
  },
  created () {
    localStorage.setItem('MapboxAccessToken', mapboxToken)
    this.$eventBus.$on('setTile', this.setTile)
  },
  mounted () {
    let _this = this
    let mapOptions = {
      container: 'map',
      center: [_this.lat, _this.long],
      zoom: _this.zoom,
      style: 'mapbox://styles/mapbox/streets-v8',
      hash: true
    }

    let secondMapOptions = {
      container: 'second-map',
      center: [_this.lat, _this.long],
      zoom: _this.zoom,
      style: 'mapbox://styles/mapbox/satellite-v9',
      hash: true
    }

    mapboxgl.accessToken = _this.token

    this.map1 = new mapboxgl.Map(mapOptions)
    this.map2 = new mapboxgl.Map(secondMapOptions)
    this.addControls(this.map1, this.map2)

    syncMove(this.map1, this.map2)

    window.addEventListener('resize', () => {
      clearTimeout(this.resizeTimeout)
      this.resizeTimeout = setTimeout(() => {
        this.syncMaps()
      }, 200)
    })
  },
  methods: {
    syncMaps () {
      this.map.resize()
      this.secondMap.resize()
    },
    newLayer (options) {
      if (options && options.mapId) {
        let map = this['map' + options.mapId]
        map.addSource('tileLayer', {
          'attribution': 'hello11',
          'type': 'raster',
          'tiles': [this.currentTile],
          'tileSize': 256
        })

        map.addLayer({
          'id': 'test-layer',
          'type': 'raster',
          'source': 'tileLayer'
        })
      }
    },
    setTile (options) {
      if (options && options.mapId) {
        this.$store.commit('setCurrentTile', options.tile)
        let map = this['map' + options.mapId]

        const source = map.getSource('tileLayer')

        if (source) {
          map.removeLayer('test-layer')
          map.removeSource('tileLayer')
        }
        this.newLayer(options)
      }
    },
    addControls () {
      for (let i in arguments) {
        let map = arguments[i]

        map.addControl(new MapboxGeocoder({
          accessToken: this.token
        }))
        map.addControl(new mapboxgl.NavigationControl())
        map.addControl(new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        }))
      }
    }
  }
}
</script>
